import Trophy from "../assets/components/Trophy.svg";
import Ticket from "../assets/components/Ticket.svg";
import CashPrice from "../assets/components/CashPrize.svg";
import taycan from "../assets/layout/Ape.png";
import { useEffect, useState } from "react";
import { LandingStats } from "../graphql/api";
import { useTokenPrice } from "../helpers/useTokenPrice";
import { isSameAddress } from "../helpers/address";
import {
  DEFAULT_ERC20,
  formatEther,
  getEvmosAmount,
  ZERO_ADDRESS,
} from "../helpers/payTokens";

function TopBanner() {
  const [stats, setStats] = useState({ totalCount: 0, totalWinners: 0 });
  const { getTokenPrice } = useTokenPrice();

  useEffect(() => {
    LandingStats().then(async (s) => {
      let data = s.data.slice();
      for (let i = 0; i < data.length; i++) {
        if (
          isSameAddress(data[i].payToken, DEFAULT_ERC20) ||
          isSameAddress(data[i].payToken, ZERO_ADDRESS)
        ) {
          data[i] = {
            ...data[i],
            evmosPrice: 1,
            etherVolume: formatEther(data[i].volume, data[i].payToken),
          };
        } else {
          const price = await getTokenPrice(s.data[i].payToken.toLowerCase());
          data[i] = {
            ...data[i],
            evmosPrice: price,
            etherVolume: formatEther(data[i].volume, data[i].payToken),
          };
        }
      }

      setStats({ ...s, data });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getTotalVolume() {
    let totalVolume = 0;
    stats?.data?.map(
      (d) =>
        (totalVolume += getEvmosAmount(parseFloat(d.etherVolume), d.evmosPrice))
    );
    return totalVolume.toFixed(0);
  }

  return (
    <div className="w-full flex flex-col md:gap-0 gap-10 items-center max-w-6xl">
      <div className="flex max-w-4xl  md:flex-row flex-col gap-10 mt-0 items-center justify-center w-full">
        <div className="flex text-purple font-bold  text-4xl w-full flex-col">
          <span className="leading-8 uppercase">
            Create and enter
            <br />
            fully decentralized
            <br />
            and provable raffles
            <br />
            on Evmos!
          </span>
          <span className="text-white leading-6 mt-1.5 font-semibold text-2xl">
            Are you our next
            <br />
            lucky winner?
          </span>
        </div>
        <img src={taycan} alt="cover" className="w-96 hidden md:flex" />
      </div>

      <div className="bg-dark border-purple  border-2 z-40 realtive md:h-24 rounded-lg l mt-0 w-full max-w-4xl">
        <div className="bg-purple gap-5 bg-opacity-10 md:px-12 w-full flex md:flex-row flex-col justify-between rounded-lg  h-full md:items-center">
          <div className="flex gap-1 items-center">
            <img className="w-14" alt="trophy" src={Trophy} />
            <div className="flex pt-1 flex-col">
              <span className="font-bold text-white text-3xl">
                {stats.totalWinners}
              </span>
              <span className="font-bold -mt-2 text-white">Total Winners</span>
            </div>
          </div>
          <div className="flex gap-1 items-center">
            <img className="w-14" alt="trophy" src={Ticket} />
            <div className="flex pt-1 flex-col">
              <span className="font-bold text-white text-3xl">
                {stats.totalCount}
              </span>
              <span className="font-bold -mt-2 text-white">Tickets Minted</span>
            </div>
          </div>
          <div className="flex gap-1 items-center">
            <img className="w-14" alt="trophy" src={CashPrice} />
            <div className="flex pt-1 flex-col">
              <span className="font-bold text-white text-3xl">
                {getTotalVolume()} EVMOS
              </span>
              <span className="font-bold -mt-2 text-white">Ticket Volume</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopBanner;
