import { useEffect, useState } from "react";
import Modal from "react-modal";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    borderRadius: "0.5rem",
    padding: "0px",
    backgroundColor: "rgba(13, 15,16, 0.9)",
  },
  overlay: {
    backgroundColor: "rgba(7, 9,10, 0.8)",
  },
};

export default function Terms() {
  const [termsAccepted, setTermsAccepted] = useState(false);

  function setTerms() {
    if (typeof window !== "undefined") {
      window.localStorage.setItem("terms", true);
    }
    setTermsAccepted(true);
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      const item = window.localStorage.getItem("terms");
      console.log("ietms", item);
      setTermsAccepted(item);
    }
  }, []);

  return (
    <>
      {!termsAccepted && (
        <Modal isOpen={true} style={customStyles} contentLabel="Example Modal">
          <div className="bg-dark rounded-lg text-white bg-opacity-5 border-2 border-purple p-4 flex max-w-2xl flex-col  items-center justify-center gap-2">
            <span className="font-medium">Terms & Conditons</span>
            <div
              className="text-sm flex gap-5 flex-col overflow-y-scroll"
              style={{ maxHeight: 400 }}
            >
              Rumble by Orbital Apes is a fully decentralized platform that
              offers a web3 raffle system completely open to the public. Rumble
              operates automatically using smart contracts that ensure full
              transparency and verifiability of results. All winner results are
              generated on-chain and require no intervention by the team. By
              entering a raffle, the user understands that the Orbital Apes team
              has no influence on the results of a draw, can’t cancel the draw
              or provide any refunds as every transaction is handled in a
              decentralized manner. Any raffle, auto funded raffle, game or any
              other offering inside Rumble is strictly meant for entertainment
              purposes and it’s the user’s full responsibility when interacting
              with them. Rumble does not promote activities such as gambling and
              betting and only provides the platform to generate on-chain
              results to draw winners. The different use cases of Rumble depend
              solely on the user and have no endorsement by the Orbital Apes
              team. The team will not be liable for any loss that the user may
              experience when using the platform and it’s at the user’s sole
              discretion to measure the risks associated with creating or buying
              a raffle. The Orbital Apes team provides full transparency so that
              users may accurately measure the risk associated with entering any
              of the offerings in the platform. The user has no claims against
              Orbital Apes for any loss associated with the use of the platform.
              Rumble uses Virtual Assets (Cryptocurrency) exclusively, which
              aren’t considered legal tender and are used as a medium of
              exchange in virtual platforms such as Rumble itself. It is up to
              the user’s discretion to use or even access the website as allowed
              by the jurisdiction they reside on. By interacting with any
              element in the Rumble platform, the user acknowledges having read
              and agreed to these terms and conditions.
            </div>
            <button
              onClick={() => setTerms()}
              className="rounded bg-purple w-32 self-center px-2"
            >
              Accept
            </button>
          </div>
        </Modal>
      )}
    </>
  );
}
