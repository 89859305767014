import { customStyles } from "../../styles/Select";
import Select from "react-select";

const BG_COLOUR = "bg-darkGray";

const createOption = (option) => ({
  ...option,
  className: `border-t hover:bg-dark cursor-pointer border-dark border-solid mx-2 p-3 ${BG_COLOUR} font-bold`,
  key: option.label,
});

export const PRICE_HIGH_TO_LOW = createOption({
  value: { orderBy: "price", orderDirection: "desc" },
  label: "Price: High to Low",
});
export const PRICE_LOW_TO_HIGH = createOption({
  value: { orderBy: "price", orderDirection: "asc" },
  label: "Price: Low to High",
});
export const CREATED_AT = createOption({
  value: { orderBy: "createdAt", orderDirection: "desc" },
  label: "Recently Added",
});
export const ENDING_SOON = createOption({
  value: { orderBy: "end", orderDirection: "asc" },
  label: "Ending soon",
});

export const PROGRESS_HIGH_LOW = createOption({
  value: { orderBy: "progress", orderDirection: "desc" },
  label: "Amount sold: High to Low",
});
export const PROGRESS_LOW_HIGH = createOption({
  value: { orderBy: "progress", orderDirection: "asc" },
  label: "Amount sold: Low to High",
});

export const options = [
  ENDING_SOON,
  CREATED_AT,
  PRICE_HIGH_TO_LOW,
  PRICE_LOW_TO_HIGH,
  PROGRESS_HIGH_LOW,
  PROGRESS_LOW_HIGH,
];

export default function SortingDropdownMenu({ value, onChange }) {
  return (
    <div style={{ width: 226, height: 50 }}>
      <div className="z-10">
        <Select
          value={value}
          name="sort"
          options={options}
          isClearable={false}
          styles={customStyles}
          className="w-64"
          placeholder="Sort by..."
          classNamePrefix="select"
          onChange={(a) => onChange(a)}
        />
      </div>
    </div>
  );
}
