import { useState } from "react";
import cs from "classnames";
import MyTickets from "./MyTickets";
import MyPrizes from "./MyPrizes";
import MyRaffles from "./MyRaffles";
import Footer from "./Footer";

function Profile() {
  const [activeMenu, setActiveMenu] = useState(1);

  function renderMenu() {
    if (activeMenu === 1) {
      return (
        <>
          {" "}
          <span className="text-3xl mb-5 font-bold">My Tickets</span>
          <MyTickets />
        </>
      );
    } else if (activeMenu === 2) {
      return (
        <>
          {" "}
          <span className="text-3xl mb-10 font-bold">My Prizes</span>
          <MyPrizes />
        </>
      );
    } else if (activeMenu === 3) {
      return (
        <>
          {" "}
          <span className="text-3xl mb-10 font-bold">My Raffles</span>
          <MyRaffles />
        </>
      );
    }
  }
  return (
    <div className=" bg-dark flex flex-col justify-center ">
      <div
        style={{
          minHeight: "100vh",
        }}
        className="pt-8 z-40 justify-center  flex w-full "
      >
        <div className="max-w-6xl flex flex-col w-full">
          <div className="text-2xl mb-4 w-full text-center py-2 font-semibold ">
            Profile {"&"} Settings
          </div>
          <div className=" bg-purple bg-opacity-5 flex self-center rounded h-10 border-purple border">
            <button
              onClick={() => setActiveMenu(1)}
              className={cs(
                {
                  "border-t border-b border-purple bg-purple bg-opacity-20":
                    activeMenu === 1,
                },
                "text-lg  font-semibold hover:bg-opacity-20 py-1 px-3"
              )}
            >
              My Tickets
            </button>
            <button
              onClick={() => setActiveMenu(2)}
              className={cs(
                {
                  "border-t border-b border-purple bg-purple bg-opacity-20":
                    activeMenu === 2,
                },
                "text-lg  font-semibold hover:bg-opacity-20 py-1 px-3"
              )}
            >
              My Prizes
            </button>
            <button
              onClick={() => setActiveMenu(3)}
              className={cs(
                {
                  "border-t border-b border-purple bg-purple bg-opacity-20":
                    activeMenu === 3,
                },
                "text-lg  font-semibold hover:bg-opacity-20 py-1 px-3"
              )}
            >
              My Raffles
            </button>
          </div>
          <div className="w-full pl-5 md:pl-10  flex flex-col ">
            {renderMenu()}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Profile;
