import cs from "classnames";

import Filter from "./Filter";

import Check from "../../assets/icons/check.svg";

function CollectionRow({
  onCollectionSelected,
  collection,
  selectedCollection,
}) {
  const isSelected =
    selectedCollection &&
    selectedCollection?.toLowerCase() === collection?.contract?.toLowerCase();
  return (
    <div
      onClick={() => onCollectionSelected(collection?.contract?.toLowerCase())}
      key={collection?.contract?.toLowerCase()}
      className={cs(
        "flex px-1 py-3 cursor-pointer bg-purple bg-opacity-0 hover:bg-opacity-20  justify-between items-center"
      )}
    >
      <div className="flex justify-between align-center w-full">
        <div className="flex items-center gap-2">
          {isSelected ? (
            <div className="border text-center border-purple p-1.5 rounded-full w-8 h-8">
              <img alt="check" src={Check} />
            </div>
          ) : (
            <img
              alt="collection"
              src={collection?.imageURL}
              className="w-8 rounded-full"
            />
          )}
          <span className="text-white">{collection?.name}</span>
        </div>
      </div>
    </div>
  );
}

export default function CollectionFilter({
  onCollectionSelected,
  collections,
  selectedCollection,
}) {
  return (
    <Filter label="NFT Collection">
      <div className="flex py-2 flex-col">
        {collections.map((collection) => {
          return (
            <CollectionRow
              onCollectionSelected={onCollectionSelected}
              collection={collection}
              selectedCollection={selectedCollection}
              key={collection?.contract?.toLowerCase()}
            />
          );
        })}
      </div>
    </Filter>
  );
}
