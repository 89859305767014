import Footer from "../Footer";
import RafflesVolume from "./RafflesVolume";
import { useEffect, useState } from "react";
import { LandingStats } from "../../graphql/api";
import { isSameAddress } from "../../helpers/address";
import {
  DEFAULT_ERC20,
  formatEther,
  getEvmosAmount,
  ZERO_ADDRESS,
} from "../../helpers/payTokens";
import { useTokenPrice } from "../../helpers/useTokenPrice";
import RafflesPlayers from "./RafflesPlayers";
import UsersWon from "./UsersWon";
import UsersCreated from "./UsersCreated";
import UsersVolume from "./UsersVolume";
import UsersParticipated from "./UsersParticipated";

export default function Stats() {
  const { getTokenPrice } = useTokenPrice();
  const [stats, setStats] = useState({ totalCount: 0, totalWinners: 0 });

  useEffect(() => {
    LandingStats().then(async (s) => {
      let data = s.data.slice();
      for (let i = 0; i < data.length; i++) {
        if (
          isSameAddress(data[i].payToken, DEFAULT_ERC20) ||
          isSameAddress(data[i].payToken, ZERO_ADDRESS)
        ) {
          data[i] = {
            ...data[i],
            evmosPrice: 1,
            etherVolume: formatEther(data[i].volume, data[i].payToken),
          };
        } else {
          const price = await getTokenPrice(s.data[i].payToken.toLowerCase());
          data[i] = {
            ...data[i],
            evmosPrice: price,
            etherVolume: formatEther(data[i].volume, data[i].payToken),
          };
        }
      }

      setStats({ ...s, data });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getTotalVolume() {
    let totalVolume = 0;
    stats?.data?.map(
      (d) =>
        (totalVolume += getEvmosAmount(parseFloat(d.etherVolume), d.evmosPrice))
    );
    return totalVolume.toFixed(0);
  }

  return (
    <div className=" flex bg-dark justify-center " style={{}}>
      <div
        style={{
          minHeight: "100%",
        }}
        className="pt-24 z-40 items-center gap-10 flex-col flex w-full "
      >
        <div className="flex w-full items-center px-10 flex-col">
          <div className="flex w-full max-w-6xl  flex-col">
            <div className="flex z-30 gap-4 flex-col">
              <span className=" font-bold text-4xl">Rumble Stats</span>
              <span className="">
                All stats are displayed in EVMOS. <br />
                Stats are only for EVMOS ticket based raffles.
              </span>
              <div className="flex mt-14 flex-wrap gap-7 ">
                <div className="flex gap-2 flex-col border bg-purple bg-opacity-10 border-purple rounded px-3 py-2.5 w-52">
                  <span className="font-bold text-3xl">
                    {stats.totalWinners}
                  </span>
                  <span className="font-bold">Drawn raffles</span>
                </div>
                <div className="flex gap-2 flex-col border bg-purple bg-opacity-10 border-purple rounded px-3 py-2.5 w-52">
                  <span className="font-bold text-3xl">
                    {getTotalVolume()} EVMOS
                  </span>
                  <span className="font-bold">Ticket volume</span>
                </div>
                <div className="flex gap-2 flex-col border bg-purple bg-opacity-10 border-purple rounded px-3 py-2.5 w-52">
                  <span className="font-bold text-3xl">{stats.totalCount}</span>
                  <span className="font-bold">Tickets sold</span>
                </div>
                <div className="flex gap-2 flex-col border bg-purple bg-opacity-10 border-purple rounded px-3 py-2.5 w-52">
                  <span className="font-bold text-3xl">{stats.totalPools}</span>
                  <span className="font-bold">Raffles created</span>
                </div>
                <div className="flex gap-2 flex-col border bg-purple bg-opacity-10 border-purple rounded px-3 py-2.5 w-52">
                  <span className="font-bold text-3xl">{stats.users}</span>
                  <span className="font-bold">Unique users</span>
                </div>
              </div>
              <div className="flex mt-20 gap-8 flex-col">
                <span className=" font-bold text-4xl">Raffles</span>
                <div className="flex gap-20 flex-wrap">
                  <RafflesVolume />
                  <RafflesPlayers />
                </div>
              </div>
              <div className="mt-16 flex gap-8 flex-col">
                <span className=" font-bold text-4xl">Users</span>
                <div className="flex gap-7 flex-wrap ">
                  <UsersWon />
                  <UsersCreated />
                  <UsersVolume />
                  <UsersParticipated />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
