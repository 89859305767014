import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
export const LISTED_ITEMS_PER_PAGE = 4;

const APIURL =
  "https://subgraph.satsuma-prod.com/09c9cf3574cc/orbital-apes/OrbitRumble/api";

const ORBITAPI =
  "https://subgraph.satsuma-prod.com/09c9cf3574cc/orbital-apes/OrbitMarket/api";

const AllUserPoolsQuery = `
  query allUserPools($poolFilter: Pool_filter, $address: String) {
    pools(where: $poolFilter ) {
      id
      poolAddress
      prizeToken
      amount
      type
      tokenId
      nft
      payToken
      price
      max
      start
      end
      totalSupply
      currentValue
      resulted
      balances(where: {owner: $address} ) {
        id
        count
        owner
      }
      creator_meta {
        verified
        name
        image
        id
      }
    }
  }
`;

const MyTokensQuery = `
  query tickets($address: String, $poolId: String) {
    tokens(where: {ownerAddress: $address, poolId: $poolId}) {
        id
        tokenId
        ownerAddress
      }
  }
`;

export const MyTokens = async (address, poolId) => {
  const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(MyTokensQuery),
    variables: {
      address,
      poolId: poolId,
    },
  });

  return res.data.tokens;
};

export const AllUserPools = async (address, resulted) => {
  const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(AllUserPoolsQuery),
    variables: {
      address,
      poolFilter: { balances_: { owner: address }, resulted: resulted },
    },
  });

  return res.data.pools;
};

const MyTokensPoolsQuery = `
  query tickets($ids: [Int]) {
    pools(where: {id_in: $ids}) {
      id
      poolAddress
      prizeToken
      amount
      type
      tokenId
      nft
      payToken
      price
      max
      start
      end
      totalSupply
      currentValue
      resulted
      creator_meta {
        verified
        name
        image
        id
      }
      winners {
        id
        pool
        winnerAddress
        winnerTokenId
      }
    }
  }
`;

export const MyTokensPools = async (ids) => {
  const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  const topPoolsData = await client.query({
    query: gql(MyTokensPoolsQuery),
    variables: {
      ids,
    },
  });

  return topPoolsData;
};

const PoolQuery = `
  query tickets($id: String) {
    pool(id: $id) {
      id
      poolAddress
      payToken
      price
      max
      progress
      nft
      tokenId
      amount
      seedSet
      start
      end
      totalSupply
      currentValue
      resulted
      creator
      prizeToken
      type
      creator_meta {
        verified
        name
        image
        id
      }
      winners {
        id
        winnerAddress
        winnerTokenId
      }
      balances (first: 1000) {
        id
        count
        owner
        winReceipts {
          id
        }
      }
    }
  }
`;

export const Pool = async (id) => {
  const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  const response = await client.query({
    query: gql(PoolQuery),
    variables: {
      id,
    },
  });

  const poolFromGraph = response?.data?.pool;

  return { ...poolFromGraph };
};

const TokensQuery = `
  query payTokens($time: Int) {
    paytokens(where: {tokens_: {start_lt: $time, end_gt: $time, resulted: false}}) {
      id
    }
  }
`;

export const PayTokens = async () => {
  const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  const response = await client.query({
    query: gql(TokensQuery),
    variables: {
      time: parseInt(new Date().getTime() / 1000),
    },
  });

  return response?.data?.paytokens || [];
};

const OpenPoolsAndMyTokens = `
  query tickets($address: String, $poolFilter: Pool_filter, $orderBy: String, $orderDirection: String ) {
    pools(first:1000, where: $poolFilter, orderBy: $orderBy, orderDirection:$orderDirection) {
      id
      poolAddress
      prizeToken
      amount
      type
      tokenId
      nft
      payToken
      price
      max
      start
      end
      totalSupply
      currentValue
      resulted
      creator_meta {
        verified
        name
        image
        id
      }
      balances(where: {owner: $address} ) {
        id
        count
        owner
      }
    }
  }
`;

const ActivePoolsInIds = `
  query tickets($address: String, $time: Int, $ids: [Int]) {
    pools(where: {start_lt: $time, end_gt: $time, id_in: $ids}) {
      id
      poolAddress
      prizeToken
      amount
      type
      tokenId
      nft
      payToken
      price
      max
      start
      end
      totalSupply
      currentValue
      resulted
      creator_meta {
        verified
        name
        image
        id
      }
      balances(where: {owner: $address} ) {
        id
        count
        owner
      }
    }
  }
`;

const HotPoolsQuery = `
  query hotPools( $time: Int) {
    pools(first:4, orderBy: progress, orderDirection: desc, where: {start_lt: $time, end_gt: $time, resulted: false}) {
      id
      poolAddress
      prizeToken
      amount
      type
      tokenId
      nft
      payToken
      price
      max
      start
      end
      totalSupply
      currentValue
      progress
      resulted
      creator_meta {
        verified
        name
        image
        id
      }
      balances(where: {owner: $address} ) {
        id
        count
        owner
      }
    }
  }
`;

// const CustomApePoolsQuery = `
//   query hotPools( $time: Int) {
//     pools(first:4, orderBy: progress, orderDirection: desc, where: {nft: "0xcce4071459c70465428e1f03d0f381b4f4ea04af"}) {
//       id
//       poolAddress
//       prizeToken
//       amount
//       type
//       tokenId
//       nft
//       payToken
//       price
//       max
//       start
//       end
//       totalSupply
//       currentValue
//       progress
//       resulted
//       creator_meta {
//         verified
//         name
//         image
//         id
//       }
//       balances(where: {owner: $address} ) {
//         id
//         count
//         owner
//       }
//     }
//   }
// `;

export const HotPools = async (address) => {
  const query = HotPoolsQuery;

  const time = parseInt(new Date().getTime() / 1000);

  let variables = {
    time: time,
    address,
  };

  const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  const result = await client.query({
    query: gql(query),
    variables,
  });

  const custom = {
    data: {
      pools: []
    }
  }

  const { data } = result;
  const poolsFromGraph = data?.pools;

  const mergedResults = [...(custom?.data?.pools ?? []), ...poolsFromGraph];

  return mergedResults;
};

export const AutoPools = async (ids, address) => {
  const query = ActivePoolsInIds;

  const time = parseInt(new Date().getTime() / 1000);

  let variables = {
    address,
    time: time,
    ids,
  };

  const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  const result = await client.query({
    query: gql(query),
    variables,
  });

  const { data } = result;
  const poolsFromGraph = data?.pools;

  const mergedResults = poolsFromGraph;

  return mergedResults;
};
export const OpenPoolsAndMyTokensQuery = async (address, filters, sorting) => {
  const time = parseInt(new Date().getTime() / 1000);
  let orderBy = sorting?.orderBy;

  let orderDirection = sorting?.orderDirection;

  let variables = {
    address,
    poolFilter: { ...filters, start_lt: time, end_gt: time, resulted: false },
    orderBy,
    orderDirection,
  };

  const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  const result = await client.query({
    query: gql(OpenPoolsAndMyTokens),
    variables,
  });

  const { data } = result;
  const poolsFromGraph = data?.pools;

  return poolsFromGraph;
};

const DrawnPoolsQuery = `
query resultedPools {
  pools(where: {resulted: true}) {
    id
    poolAddress
    prizeToken
    amount
    type
    tokenId
    nft
    payToken
    price
    max
    start
    end
    totalSupply
    currentValue
    resulted
    creator_meta {
      verified
      name
      image
      id
    }
    winners {
      id
      winnerAddress
      winnerTokenId
    }
  }
}
`;

export const DrawnPools = async () => {
  const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  const topPoolsData = await client.query({
    query: gql(DrawnPoolsQuery),
  });

  return topPoolsData;
};

const PastPoolsQuery = `
query resultedPools($time: Int) {
  pools(first: 1000, where: {end_lt: $time}, orderBy: end, orderDirection:desc) {
    id
    poolAddress
    prizeToken
    amount
    type
    tokenId
    nft
    payToken
    price
    max
    start
    end
    totalSupply
    currentValue
    resulted
    creator_meta {
      verified
      name
      image
      id
    }
    winners {
      id
      winnerAddress
      winnerTokenId
    }
  }
}
`;

export const PastPools = async () => {
  const time = parseInt(new Date().getTime() / 1000);
  const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(PastPoolsQuery),
    variables: {
      time: time,
    },
  });

  return res.data.pools;
};

const HighestVolumePoolsQuery = `
query highestVolumePools($time: Int) {
  pools(first: 10, orderBy: currentValue, orderDirection:desc, where: {payToken_in: ["0x0000000000000000000000000000000000000000", "0xd4949664cd82660aae99bedc034a0dea8a0bd517"]}) {
    id
    poolAddress
    prizeToken
    amount
    type
    tokenId
    nft
    payToken
    currentValue
    creator
  }
}
`;

export const UsersMostWon = async () => {
  const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(UsersMostwonQuery),
  });

  return res.data.userInfos;
};

const UsersMostwonQuery = `
query highestVolumePools {
  userInfos(first: 10, orderBy: winCount, orderDirection:desc) {
    id
    winCount
  }
}
`;

export const UsersMostCreated = async () => {
  const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(UsersMostCreatedQuery),
  });

  return res.data.userInfos;
};

const UsersMostCreatedQuery = `
query highestVolumePools {
  userInfos(first: 10, orderBy: poolCount, orderDirection:desc) {
    id
    poolCount
  }
}
`;

export const UsersMostParticipated = async () => {
  const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(UsersMostParticipatedQuery),
  });

  return res.data.userInfos;
};

const UsersMostParticipatedQuery = `
query highestVolumePools {
  userInfos(first: 10, orderBy: participationCount, orderDirection:desc) {
    id
    participationCount
  }
}
`;

export const UsersMostVolume = async () => {
  const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(UsersMostVolumeQuery),
  });

  return res.data.userInfos;
};

const UsersMostVolumeQuery = `
query highestVolumePools {
  userInfos(first: 10, orderBy: volume, orderDirection:desc) {
    id
    volume
  }
}
`;

export const HighestVolumePools = async () => {
  const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(HighestVolumePoolsQuery),
  });

  return res.data.pools;
};

const HighestPlayersPoolsQuery = `
query highestVolumePools($time: Int) {
  pools(first: 10, orderBy: playerCount, orderDirection:desc, where: {payToken_in: ["0x0000000000000000000000000000000000000000", "0xd4949664cd82660aae99bedc034a0dea8a0bd517"]}) {
    id
    poolAddress
    prizeToken
    amount
    type
    tokenId
    nft
    payToken
    playerCount
    creator
  }
}
`;

export const HighestPlayersPools = async () => {
  const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(HighestPlayersPoolsQuery),
  });

  return res.data.pools;
};

const MyPrizesQuery = `
  query pools($address: String) {
    pools(where: {winners_: {winnerAddress: $address}}){
        id
        end
        type
        nft
        prizeToken
        tokenId
        amount
        creator_meta {
          verified
          name
          image
          id
        }
        winners {
          id
          winnerAddress
          winnerTokenId
        }
      }
    }
`;

export const MyPrizes = async (address) => {
  const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(MyPrizesQuery),
    variables: {
      address,
    },
  });

  return res?.data?.pools;
};

const MyRafflesQuery = `
  query pools($address: String) {
    pools(where: {creator: $address}){
      id
      poolAddress
      payToken
      price
      max
      nft
      tokenId
      amount
      seedSet
      start
      end
      totalSupply
      createdAt
      currentValue
      resulted
      creator
      prizeToken
      creator_meta {
        verified
        name
        image
        id
      }
      type
        winners {
          id
          winnerAddress
          winnerTokenId
        }
      }
    }
`;

export const MyRaffles = async (address) => {
  const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(MyRafflesQuery),
    variables: {
      address,
    },
  });

  return res.data?.pools;
};

const MyNFTTokensQuery = `
  query myOrbitNFTS($address: String, $nftAddress: String) {
    tokens(where: {owner: $address, nftAddress: $nftAddress}){
        tokenId
        name
        nftAddress
      }
    }
`;

export const MyNFTTokens = async (address, nftAddress) => {
  const client = new ApolloClient({
    uri: ORBITAPI,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(MyNFTTokensQuery),
    variables: {
      address,
      nftAddress,
    },
  });

  return res.data?.tokens;
};

const LandingStatsQuery = `
  query landingStats {
    rumbleSummaries {
      volume
      count
      winners
      payToken
      users
      poolsCreated
    }  
  }
`;

export const LandingStats = async () => {
  const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(LandingStatsQuery),
  });

  let totalCount = 0;
  let totalWinners = 0;
  let totalUsers = [];
  let totalPools = 0;

  res.data.rumbleSummaries.forEach((c) => {
    totalCount += c.count;
    totalWinners += c.winners;
    totalUsers = [...totalUsers, ...c.users];
    totalPools += c.poolsCreated;
  });

  return {
    totalPools,
    users: new Set(totalUsers).size,
    totalCount,
    totalWinners,
    data: res.data.rumbleSummaries,
  };
};

const AnalyticsQuery = `
query analytics($payToken: String) {
  dailyPoolSummaries(first:3, orderBy: volume, orderDirection:desc, where: {payToken: $payToken}) {
    id
    pool {
      id
      name
      image
      description
    }
    volume
    count
    payToken
    price
    timestamp
  }
  dailyRumbleSummaries(first: 365, orderBy: timestamp, orderDirection: asc, where: {payToken: $payToken}) {
    id
    timestamp
    volume
    count
    userCount
    poolsCreated
    payToken
  }
  rumbleSummaries(first: 365, orderBy: timestamp, orderDirection: asc, where: {payToken: $payToken}) {
    id
    timestamp
    volume
    count
    userCount
    poolsCreated
    payToken
  }
  rumbleSummary(id: "0") {
    id
    timestamp
    volume
    count
    userCount
    poolsCreated
    payToken
  }
}
`;

export const Analytics = async (filters) => {
  const client = new ApolloClient({
    uri: APIURL,
    cache: new InMemoryCache(),
  });

  const res = await client.query({
    query: gql(AnalyticsQuery),
    variables: {
      payToken: filters.payToken,
    },
  });

  return res.data;
};
