import Step1 from "../assets/icons/Step1.svg";
import Step2 from "../assets/icons/Step2.svg";
import Step2G from "../assets/icons/Step2G.svg";
import cs from "classnames";

export default function Approve({
  approve,
  action,
  success,
  applyStep1,
  applyStep2,
  step,
  loading,
  closeModal,
  approveStep = 1,
  actionStep = 2,
}) {
  function renderButtonLabel() {
    if (loading) return "Loading..";
    if (step === approveStep) return approve;
    else if (step === actionStep) return action;
    else return success;
  }

  return (
    <>
      <div className="flex border-gray gap-6 flex-col  py-6 justify-between  ">
        {step === approveStep && (
          <span className="text-gray px-10 text-center">
            Your tokens have to be approved first, before submitting your order.
          </span>
        )}
        <div
          style={{ minWidth: 400 }}
          className="flex px-20 justify-between items-center"
        >
          <div
            className={cs("flex items-center flex-col", {
              "animate-pulse": step === approveStep,
            })}
          >
            <img className="w-10" alt="Step1" src={Step1} />
            <span className={cs("text-purple")}>{approve}</span>
          </div>

          <div
            className={cs("flex items-center flex-col", {
              "animate-pulse": step === actionStep,
            })}
          >
            <img
              className="w-10"
              alt="Step1"
              src={step >= actionStep ? Step2 : Step2G}
            />
            <span
              className={cs({
                "text-darkGray": step === approveStep,
                "text-purple": step >= actionStep,
              })}
            >
              {action}
            </span>
          </div>
        </div>
        <div className="flex  pt-6 border-gray flex-1 justify-center">
          <button
            disabled={loading}
            className={cs("font-bold h-8 text-white rounded-md w-40", {
              "bg-purple": !loading,
              "bg-purple opacity-40": loading && step <= actionStep,
            })}
            onClick={() =>
              step > actionStep
                ? closeModal()
                : step === approveStep
                ? applyStep1()
                : applyStep2()
            }
          >
            {renderButtonLabel()}
          </button>
        </div>
      </div>
    </>
  );
}
