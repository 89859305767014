import React, { useEffect } from "react";
import "./App.css";
import BaseLayout from "./layout/BaseLayout";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import { Symfoni } from "./hardhat/SymfoniContext";
import Winners from "./pages/Winners";
import Detail from "./pages/Detail";
import { Toaster } from "react-hot-toast";
import Terms from "./components/Terms";
import Stats from "./pages/Stats/Stats";

function App() {
  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.removeAllListeners();
      window.ethereum.on("chainChanged", () => {
        document.location.reload();
      });
    }
  });

  function renderApp() {
    return (
      <>
        <Terms />
        <Toaster
          toastOptions={{
            className: "",
            style: {
              background: "#121619",
              border: "1px solid #21262a",
              padding: "16px",
              color: "#fff",
            },
          }}
          position="top-right"
        />
        <Switch>
          <Route exact path="/winners">
            <Symfoni autoInit={false} showLoading={false}>
              <BaseLayout>
                <Winners />
              </BaseLayout>
            </Symfoni>
          </Route>
          <Route exact path="/pool/nft/:poolId">
            <Symfoni autoInit={false} showLoading={false}>
              <BaseLayout>
                <Detail type="nft" />
              </BaseLayout>
            </Symfoni>
          </Route>
          <Route exact path="/pool/token/:poolId">
            <Symfoni autoInit={false} showLoading={false}>
              <BaseLayout>
                <Detail type="token" />
              </BaseLayout>
            </Symfoni>
          </Route>
          <Route exact path="/profile">
            <Symfoni autoInit={false} showLoading={false}>
              <BaseLayout>
                <Profile />
              </BaseLayout>
            </Symfoni>
          </Route>
          <Route exact path="/stats">
            <Symfoni autoInit={false} showLoading={false}>
              <BaseLayout>
                <Stats />
              </BaseLayout>
            </Symfoni>
          </Route>
          <Route exact path="*">
            <Symfoni autoInit={false} showLoading={false}>
              <BaseLayout>
                <Home />
              </BaseLayout>
            </Symfoni>
          </Route>
        </Switch>
      </>
    );
  }

  return <BrowserRouter>{renderApp()}</BrowserRouter>;
}

export default App;
