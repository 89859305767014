import Modal from "react-modal";

import { useState } from "react";
import NewNFTRaffleModal from "./NewNFTRaffleModal";
import NewERC20ReffleModal from "./NewERC20RaffleModal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    borderRadius: "0.5rem",
    padding: "0px",
    backgroundColor: "rgba(13, 15,16, 0.9)",
  },
  overlay: {
    backgroundColor: "rgba(7, 9,10, 0.8)",
  },
};

function NewRaffleModal({ closeModal, onReset }) {
  const [step, setStep] = useState(0);

  function renderNFTType() {
    return <NewNFTRaffleModal onReset={() => setStep(0)} />;
  }

  function renderChooseType() {
    return (
      <>
        <div className="flex justify-start gap-24 w-full items-center">
          <span className="text-xl font-bold">Choose raffle type</span>
        </div>
        <div className="border w-full p-1.5 border-purple text-sm rounded bg-purple bg-opacity-10">
          Platform Fees
          <li>10% platform fees for NFT raffles</li>
          <li>5% platform fees for ERC20 raffles</li>
        </div>
        <div className="flex gap-5">
          <button
            onClick={() => setStep(2)}
            className="flex bg-purple bg-opacity-10 outline-none border px-5 py-2.5 rounded-lg border-purple"
          >
            ERC-20 Token
          </button>
          <button
            onClick={() => setStep(1)}
            className="flex bg-purple bg-opacity-10 outline-none border px-5 py-2.5 rounded-lg border-purple"
          >
            NFT (ERC-721) Token
          </button>
        </div>
      </>
    );
  }

  function renderModal() {
    if (step === 0) {
      return renderChooseType();
    }
    if (step === 1) {
      return renderNFTType();
    }
    if (step === 2) {
      return <NewERC20ReffleModal onReset={() => setStep(0)} />;
    }
  }

  return (
    <div
      style={{
        filter: "blur(2px)",
        maxHeight: "95vh",
        overflow: "auto",
      }}
    >
      <Modal
        onRequestClose={() => closeModal()}
        isOpen={true}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="bg-dark text-white rounded-lg max-w-3xl bg-opacity-5 border-2 border-purple px-6 py-4 flex  flex-col  items-center justify-center gap-4">
          {renderModal()}
        </div>
      </Modal>
    </div>
  );
}

export default NewRaffleModal;
