import Logo from "../assets/Logo.svg";
import Twitter from "../assets/socials/twitter.svg";
import Discord from "../assets/socials/discord.svg";

function Footer() {
  return (
    <div
      className="overflow-hidden w-full overflow-y-hidden pt-20"
      style={{ maxHeight: 450 }}
    >
      <div
        style={{
          width: "180%",
          height: "150vh",
          marginLeft: "-40%",
          marginBottom: "10px",
        }}
        className=" z-40 text-white justify-center flex overflow-hidden relative left-0 right-0 bottom-0 rounded-t-3xl md:rounded-t-fulll bg-gradient-to-t  from-dark via-dark to-gradientTwo"
      >
        <div
          style={{ maxHeight: "300px" }}
          className="flex w-full items-end px-32 justify-center md:justify-between md:max-w-7xl"
        >
          <div className="flex gap-0 justify-start flex-col">
            <img alt="logo" className=" cursor-pointer  w-24" src={Logo} />
          </div>
          <div className="flex gap-4 z-40 ">
            <button className=" p-2 rounded-full border-2 border-white">
              <img alt="socials" className="w-4" src={Twitter} />
            </button>
            <button className=" p-2 rounded-full border-2 border-white">
              <img alt="socials" className="w-4" src={Discord} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
