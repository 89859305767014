import { Pie, PieChart, Sector } from "recharts";
import ReactResizeDetector from "react-resize-detector";
import { useState } from "react";
import cs from "classnames";
import Ticket from "../../assets/components/Ticket.svg";
import Trophy from "../../assets/components/TrophyGreen.svg";
import Address from "../../components/Address";

export default function RafflePieChart({ data = [] }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={"#8884d8"}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={"#8884d8"}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#555"
        >{`Tickets ${value}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`( ${(percent * 100).toFixed(2)}% )`}
        </text>
      </g>
    );
  };

  let data01 = data
    .slice()
    .sort((a, b) => {
      return parseInt(b.count) - parseInt(a.count);
    })
    .sort((a, b) => {
      return a?.winReceipts?.length > 0 ? -1 : 1;
    })
    ?.map((d) => {
      return {
        name: d.owner,
        value: parseInt(d.count),
        winner: d?.winReceipts?.length > 0,
      };
    });
  return (
    <div className="flex gap-12 md:flex-row flex-col pt-12 relative  w-full border-darkGray  rounded-md">
      <div className="flex text-white md:absolute relative left-0 top-0 self-start gap-8  rounded  ">
        <div className="flex">
          <img className="mr-1.5 w-6 text-yellow" alt={"ticket"} src={Trophy} />{" "}
          - <span className="ml-2">winner</span>
        </div>
        <div className="flex">
          <img className="mr-1.5 w-6 text-yellow" alt={"ticket"} src={Ticket} />{" "}
          - <span className="ml-2">tickets</span>
        </div>
      </div>
      <div className="flex h-full justify-center relative flex-col">
        <div className="md:self-center flex flex-col">
          {data01?.map((b, index) => (
            <div
              onClick={() => setActiveIndex(index)}
              className={cs(
                "flex items-center flex-center flex-wrap cursor-pointer",
                {
                  "text-purple ": index === activeIndex,
                }
              )}
            >
              <li
                className={cs("", {
                  "text-purple ": index === activeIndex,
                })}
                key={index}
              ></li>
              <div className="overflow-hidden  flex" style={{ width: 175 }}>
                {index}. <Address address={b.name} ellipsis={true} length={7} />
              </div>
              <span className="mr-2.5 text-white">-</span>
              <div className="flex mr-4">
                <img className="w-6 mr-1.5" alt={"ticket"} src={Ticket} />
                <span className="text-white">{b.value} </span>
              </div>
              {b.winner > 0 ? (
                <div className="text-white flex">
                  <img
                    className="mr-1.5 w-6 text-yellow"
                    alt={"ticket"}
                    src={Trophy}
                  />
                  {b.count}
                </div>
              ) : null}{" "}
            </div>
          ))}
        </div>
      </div>
      <div style={{ maxWidth: "500px" }} className="w-full flex flex-col ">
        <ReactResizeDetector>
          {({ width }) =>
            width > 0 ? (
              <div className="py-3  flex  relative">
                <PieChart width={500} height={300}>
                  <Pie
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    data={data01.map((d) => {
                      return { ...d, name: d.name.substring(0, 12) };
                    })}
                    cx="50%"
                    cy="50%"
                    innerRadius={70}
                    outerRadius={100}
                    fill="#8454EB"
                    dataKey="value"
                    onMouseEnter={(_, index) => setActiveIndex(index)}
                  />
                </PieChart>
              </div>
            ) : (
              <div>{width}</div>
            )
          }
        </ReactResizeDetector>
      </div>
    </div>
  );
}
