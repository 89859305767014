import { useEVM } from "../hooks/EVMhook";
import { useEffect, useState } from "react";
import { MyRaffles } from "../graphql/api";
import NewRaffleModal from "../components/NewRaffleModal";
import { getPoolURL } from "../helpers/pools";
import {
  formatEther,
  getRaffleTitle,
  payTokenMeta,
} from "../helpers/payTokens";
import { collectionMeta } from "../helpers/collectionInfo";
import moment from "moment";
import cs from "classnames";
import arrow from "../assets/icons/arrow-down-svgrepo-com.svg";
import { isSameAddress } from "../helpers/address";

function PoolTypeChip({ type }) {
  return (
    <>
      {type === "NFT" && (
        <span className="border flex w-16 text-center justify-center bg-pink bg-opacity-30 px-2 text-sm font-medium py-0.5 rounded-md border-pink">
          {type}
        </span>
      )}

      {type === "TOKEN" && (
        <span className="border flex w-16 text-center justify-center bg-purple bg-opacity-30 px-2 text-sm font-medium py-0.5 rounded-md border-purple">
          {type}
        </span>
      )}
    </>
  );
}

function MyRaffleRow({ index, data }) {
  return (
    <a
      href={getPoolURL(data)}
      className={cs(
        "flex bg-opacity-5 hover:bg-opacity-5 bg-transparent   border-t px-3 py-2 border-darkGray",
        {
          "hover:bg-purple": data?.type === "TOKEN",
          "hover:bg-pink": data?.type === "NFT",
        }
      )}
    >
      <span style={{ maxWidth: "45px" }} className="hidden md:flex w-1/12">
        # {index}
      </span>
      <div className="w-20">
        <PoolTypeChip type={data?.type} />
      </div>
      {data?.type === "NFT" && (
        <span className="w-44">{`${
          collectionMeta[data?.nft.toLowerCase()].name
        } #${
          !isSameAddress(
            "0xabbaa322a763b36587e3f63e46a81deacb2957a7",
            data?.nft.toLowerCase()
          )
            ? data?.tokenId
            : ""
        }`}</span>
      )}
      {data?.type === "TOKEN" && (
        <div className="w-44 items-center gap-4 flex">
          <span className=" overflow-x-hidden">
            {formatEther(data?.amount, data?.prizeToken)}{" "}
          </span>
          <span className="font-light text-sm w-16 font-">
            ({payTokenMeta[data?.prizeToken].name})
          </span>
        </div>
      )}
      <div className="w-44 hidden md:flex items-center gap-4 ">
        <span className=" ">{formatEther(data?.price, data?.payToken)} </span>
        <span className="font-light text-sm w-16 font-">
          ({payTokenMeta[data?.payToken].name})
        </span>
      </div>
      <span className="w-36 text-sm">{getRaffleTitle(data)}</span>
      <span className="hidden md:flex w-20">{data?.max}</span>

      <span className="hidden md:flex w-20">{data?.totalSupply}</span>

      <span className="w-32">
        {moment(new Date(data?.end * 1000).toISOString()).format("MMM Do YYYY")}
      </span>
    </a>
  );
}

const tableSortOptions = {
  type: (a, b, c) => {
    const result = c > 0 ? b.type < a.type : a.type < b.type;
    return result ? -1 : 1;
  },
  price: (a, b, c) => {
    return c > 0 ? b.price - a.price : a.price - b.price;
  },
  status: (a, b, c) => {
    const result =
      c > 0
        ? getRaffleTitle(a) < getRaffleTitle(b)
        : getRaffleTitle(b) < getRaffleTitle(a);
    return result ? -1 : 1;
  },
  sold: (a, b, c) => {
    return c > 0
      ? b.totalSupply - a.totalSupply
      : a.totalSupply - b.totalSupply;
  },
  end: (a, b, c) => {
    return c > 0
      ? parseInt(b.end) - parseInt(a.end)
      : parseInt(a.end) - parseInt(b.end);
  },
  supply: (a, b, c) => {
    return c > 0 ? b.max - a.max : a.max - b.max;
  },
};

function MyRafflesMenu() {
  const { address } = useEVM();
  const [data, setData] = useState();
  const [fetching, setFetching] = useState();
  const [showModal, setShowModal] = useState(false);
  const [isSortSelected, setIsSortSelected] = useState(null);
  const [sortDirection, setSortDirection] = useState(-1);

  useEffect(() => {
    if (address) {
      setFetching(true);
      MyRaffles(address).then((d) => {
        setFetching(false);
        setData(d);
      });
    }
  }, [address]);

  function handleSortClick(e) {
    if (isSortSelected === e) {
      setSortDirection(sortDirection * -1);
    } else {
      setIsSortSelected(e);
    }
  }

  return (
    <div className="flex gap-6 flex-col">
      <button
        className="self-start bg-purple rounded-md px-3 py-1 font-semibold"
        onClick={() => setShowModal(true)}
      >
        Start New Raffle
      </button>

      {showModal && <NewRaffleModal closeModal={() => setShowModal(false)} />}

      <div className="max-w-6xl w-full overflow-x-scroll  gap-10 flex flex-col flex-wrap">
        {fetching && (
          <span className="text-white font-semibold text-xl">Loading...</span>
        )}
        <div className="flex border border-purple rounded-md flex-col flex-1">
          <div className="flex px-3 py-3 font-semibold bg-purple bg-opacity-20 rounded-t">
            <span
              style={{ maxWidth: "45px" }}
              className="hidden md:flex w-1/12"
            >
              #
            </span>
            <div
              onClick={() => handleSortClick("type")}
              className="w-20  items-center gap-0.5 flex cursor-pointer"
            >
              Type{" "}
              {isSortSelected === "type" && (
                <img className={cs("pt-1 w-3")} alt="sort" src={arrow} />
              )}
            </div>
            <div className="w-44 items-center gap-0.5 flex">
              Prize{" "}
              {isSortSelected === "end" && (
                <img className="pt-1 w-3" alt="sort" src={arrow} />
              )}
            </div>
            <div
              onClick={() => handleSortClick("price")}
              className="w-44 hidden  items-center gap-0.5 md:flex cursor-pointer"
            >
              Ticket Price{" "}
              {isSortSelected === "price" && (
                <img
                  className={cs("pt-1 w-3", {
                    "transform origin-center -rotate-180": sortDirection > 0,
                  })}
                  alt="sort"
                  src={arrow}
                />
              )}
            </div>
            <div
              onClick={() => handleSortClick("status")}
              className="w-36 items-center gap-0.5 flex cursor-pointer"
            >
              Status{" "}
              {isSortSelected === "status" && (
                <img
                  className={cs("pt-1 w-3", {
                    "transform origin-center -rotate-180": sortDirection > 0,
                  })}
                  alt="sort"
                  src={arrow}
                />
              )}
            </div>

            <div
              onClick={() => handleSortClick("supply")}
              className="w-20 hidden md:flex  items-center gap-0.5 cursor-pointer"
            >
              Supply{" "}
              {isSortSelected === "supply" && (
                <img
                  className={cs("pt-1 w-3", {
                    "transform origin-center -rotate-180": sortDirection > 0,
                  })}
                  alt="sort"
                  src={arrow}
                />
              )}
            </div>
            <div
              onClick={() => handleSortClick("sold")}
              className="w-20 hidden md:flex  items-center gap-0.5 cursor-pointer"
            >
              Sold{" "}
              {isSortSelected === "sold" && (
                <img
                  className={cs("pt-1 w-3", {
                    "transform origin-center -rotate-180": sortDirection > 0,
                  })}
                  alt="sort"
                  src={arrow}
                />
              )}
            </div>
            <div
              onClick={() => handleSortClick("end")}
              className="w-32 items-center gap-0.5 flex cursor-pointer"
            >
              End{" "}
              {isSortSelected === "end" && (
                <img
                  className={cs("pt-1 w-3", {
                    "transform origin-center -rotate-180": sortDirection > 0,
                  })}
                  alt="sort"
                  src={arrow}
                />
              )}
            </div>
          </div>
          <div
            style={{ maxHeight: 300 }}
            className="flex bg-dark overflow-y-scroll flex-col flex-1"
          >
            {!fetching &&
              data
                ?.slice()
                ?.sort((a, b) =>
                  isSortSelected
                    ? tableSortOptions[isSortSelected](a, b, sortDirection)
                    : b.createdAt - a.createdAt
                )
                ?.map((d, index) => (
                  <MyRaffleRow key={d.id} index={index} data={d} />
                ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyRafflesMenu;
