import RaffleCard from "../../components/RaffleCard";
import { useState, useEffect } from "react";
import { useEVM } from "../../hooks/EVMhook";
import { OpenPoolsAndMyTokensQuery, PayTokens } from "../../graphql/api";
import { DEFAULT_ERC20, ZERO_ADDRESS } from "../../helpers/payTokens";
import Scroll from "./Scroll";
import PayTokenFilter from "../../components/filters/PayTokenFilter";
import CollectionFilter from "../../components/filters/CollectionFilter";
import { JSONURLState, useURLState } from "../../helpers/URLState";
import { collectionMeta } from "../../helpers/collectionInfo";
import RaffleTypeFilter from "../../components/filters/RaffleTypeFilter";
import { isSameAddress } from "../../helpers/address";
import SortingDropdownMenu, {
  CREATED_AT,
} from "../../components/sorting/SortingDropdownMenu";
import CreatorFilter from "../../components/filters/CreatorFilter";
// import RenderScrollComponent from "../../components/EasyInfiniteScroll";
// import Scroll from "./Scroll";

function Pools() {
  const [currencies, setCurrencies] = useState([]);
  const { address } = useEVM();

  const {
    selectedCollection,
    setSelectedCollection,
    filters,
    setFilters,
    sortingOption,
    setSortingOption,
  } = useURLState({
    selectedCollection: JSONURLState(null),
    filters: JSONURLState({}),
    sortingOption: JSONURLState({
      value: CREATED_AT.value,
      label: CREATED_AT.label,
    }),
  });

  const sortingDropdownMenu = [
    <SortingDropdownMenu
      value={sortingOption}
      onChange={(s) => setSortingOption({ value: s.value, label: s.label })}
    />,
  ];

  const fetchPage = async (pageNum) => {
    return await OpenPoolsAndMyTokensQuery(
      address,
      filters,
      sortingOption?.value,
      pageNum
    );
  };

  useEffect(() => {
    PayTokens().then((r) => setCurrencies(r));
  }, []);

  function applyCollectionFilter(value) {
    if (filters.nft === value) {
      const newFilters = { ...filters };
      delete newFilters.nft;
      setFilters(newFilters);
      setSelectedCollection(null);
    } else {
      setFilters({ ...filters, ...{ nft: value } });
      setSelectedCollection(value);
    }
  }

  return (
    <div className="flex pb-20 justify-center flex-col items-center w-full">
      <div className="gap-8 max-w-6xl w-full flex flex-col mt-10 items-center justify-between flex-wrap">
        <span className="font-bold text-center w-full uppercase text-purple self-start text-4xl">
          CURRENT Campaigns
        </span>
        <div className="flex gap-3">
          {/* <Select
            isMulti
            name="categories"
            options={response?.categories?.map((c) => ({
              value: c,
              label: c,
            }))}
            styles={customStyles}
            className="w-48"
            placeholder="Categories..."
            classNamePrefix="select"
            onChange={(a) => handleCategoryChange(a)}
          /> */}
        </div>
      </div>

      <Scroll
        filterComponentList={[
          <RaffleTypeFilter
            onApply={(e) => {
              const newFilters = { ...filters };
              if (newFilters["type"] === e) {
                delete newFilters["type"];
              } else {
                newFilters["type"] = e;
              }
              setFilters(newFilters);
            }}
          />,
          <CreatorFilter
            onApply={(f) => {
              if (f !== null) {
                setFilters({ ...filters, creator_meta_: f });
              } else {
                let old_filters = filters;
                delete old_filters["creator_meta_"];
                setFilters(old_filters);
              }
            }}
            defaultOpen={true}
          />,
          <PayTokenFilter
            currencies={currencies}
            onApply={(e) => {
              const newFilters = { ...filters };
              const newSelection = isSameAddress(e, ZERO_ADDRESS)
                ? [ZERO_ADDRESS, DEFAULT_ERC20]
                : [e];
              if (newFilters["payToken_in"] === newSelection) {
                delete newFilters["payToken_in"];
              } else {
                newFilters["payToken_in"] = newSelection;
              }
              setFilters(newFilters);
            }}
          />,
          <CollectionFilter
            onCollectionSelected={applyCollectionFilter}
            collections={Object.keys(collectionMeta).map((token) => {
              return {
                imageURL: collectionMeta[token].imageURL,
                name: collectionMeta[token].name,
                contract: token,
              };
            })}
            selectedCollection={selectedCollection}
          />,
        ]}
        onDataChanged={() => {}}
        renderData={(data) => {
          return (
            <div className="flex justify-center">
              {" "}
              <div className="flex max-w-5xl justify-center gap-10 flex-wrap">
                {data
                  ?.sort((a, b) =>
                    isSameAddress(
                      a?.nft,
                      "0xcce4071459c70465428e1f03d0f381b4f4ea04af"
                    ) > 0
                      ? -1
                      : 1
                  )
                  ?.map((d, index) => (
                    <RaffleCard key={d?.id + index} data={d} />
                  ))}
              </div>
            </div>
          );
        }}
        sortingDropdownMenu={sortingDropdownMenu}
        renderEmptyState={() => {
          return (
            <div className="flex-1 font-semibold text-2xl text-center py-20 px-8">
              There are currently no active raffles
            </div>
          );
        }}
        fetchPage={fetchPage}
        sensitivityListForPaginationReset={[filters, sortingOption, address]}
      />
    </div>
  );
}

export default Pools;
