import { useState } from "react";
import { useEVM } from "../hooks/EVMhook";
import { Link } from "react-router-dom";
import TicketsModal from "../components/TicketsModal";
import { getPoolURL, usePoolDisplayData } from "../helpers/pools";
import Trophy from "../assets/components/TrophyGreen.svg";

function MyPrizeCard({ data, metadata }) {
  const { address } = useEVM();
  const [showModal, setShowModal] = useState(false);

  const { info } = usePoolDisplayData(data);

  return (
    <div className="ticket flex gap-3 rounded-t-lg pt-5 bg-darkGray shadow-md hover:shadow-3xl w-80  text-white   rounded-lg flex-col">
      {showModal && (
        <TicketsModal
          address={address}
          data={{ ...data }}
          closeModal={() => setShowModal(false)}
        />
      )}
      <span className="w-full text-green-500  flex flex-col font-bold text-lg text-center  ">
        WINNER!
      </span>
      <div className="flex justify-between w-full  bg-dark rounded-b-lg rounded-t-lg py-2 pb-5 flex-col">
        <span className="text-white text-lg text-center self-center mt-2 font-medium">
          {info?.name}
        </span>

        <Link to={getPoolURL(data)} className="py-6 flex justify-center">
          <img
            style={{ height: 150, width: 150 }}
            className="rounded-t object-contain"
            alt="raffle preview"
            src={info?.image}
          />
        </Link>
        <div className="border-b border-purple" />
        <div className="flex gap-5 w-full p-4 self-start flex-col">
          <div className="flex items-center flex-wrap flex-1 justify-between ">
            <img src={Trophy} className="w-10" alt="trofy" />

            <div className="flex flex-col">
              <span className="text-lg font-semibold">
                Ticket #{data?.winners[0].winnerTokenId}
              </span>
            </div>
            <img src={Trophy} className="w-10" alt="trofy" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyPrizeCard;
