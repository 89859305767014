import TicketCard from "../components/TicketCard";
import { useEVM } from "../hooks/EVMhook";
import { AllUserPools } from "../graphql/api";
import { useEffect, useState } from "react";
import cs from "classnames";

function MyTickets() {
  const { address } = useEVM();
  const [data, setData] = useState();
  const [fetching, setFetching] = useState();
  const [activeMenu, setActiveMenu] = useState(0);

  useEffect(() => {
    if (address) {
      setFetching(true);

      AllUserPools(address, activeMenu === 1).then((pools) => {
        setData(pools);
        setFetching(false);
      });
    }
  }, [address, activeMenu]);

  return (
    <div className="flex gap-10 flex-col">
      <div className=" bg-purple bg-opacity-5 self-start  rounded h-8 border-purple border">
        <button
          onClick={() => {
            setActiveMenu(0);
          }}
          className={cs(
            {
              " bg-purple bg-opacity-20": activeMenu === 0,
            },
            " w-32 font-semibold hover:bg-opacity-20 py-1 px-3"
          )}
        >
          In progress
        </button>
        <button
          onClick={() => {
            setActiveMenu(1);
          }}
          className={cs(
            {
              " bg-purple bg-opacity-20": activeMenu === 1,
            },
            " w-32 font-semibold hover:bg-opacity-20 py-1 px-3"
          )}
        >
          Drawn
        </button>
      </div>
      <div className="max-w-6xl w-full gap-10 flex flex-wrap">
        {fetching && (
          <span className="text-white font-semibold text-xl">Loading...</span>
        )}
        {!fetching &&
          data
            ?.slice()
            ?.sort((a, b) => b.start - a.start)
            ?.map((d) => <TicketCard key={data?.id} data={d} />)}
      </div>
    </div>
  );
}

export default MyTickets;
