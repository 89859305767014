import { useEffect, useState } from "react";
import NewRaffleOptionsModal from "./NewRaffleOptionsModal";
import Dropdown from "react-dropdown";
import RightArrow from "../assets/icons/arrow-right-svgrepo-com.svg";
import { collectionMeta } from "../helpers/collectionInfo";
import cs from "classnames";
import { parseEther, payTokenMeta } from "../helpers/payTokens";
import { MyNFTTokens } from "../graphql/api";
import { useEVM } from "../hooks/EVMhook";
import { useApproveERC721 } from "../hooks/marketTransactions";
import Approve from "./Approve";
import NftTokenCardd from "./NftTokenCard";
import { dismissToast, loadingToast, successToast } from "./Toasts";
import { handleError } from "../helpers/errors";

const _options = Object.keys(collectionMeta).map((token) => {
  return {
    wrapped: collectionMeta[token].wrapped,
    erc: collectionMeta[token].erc,
    value: token,
    label: (
      <div className="flex items-center gap-1">
        <img
          className={` w-4 h-4 rounded-full`}
          alt="eth"
          src={collectionMeta[token].imageURL}
        />
        <span>{collectionMeta[token].name}</span>
      </div>
    ),
  };
});
const BG_COLOUR = "bg-darkGray";

export const options = _options.map((o) => ({
  ...o,
  className: `border-t hover:bg-dark cursor-pointer border-dark border-solid mx-2 p-3 py-1.5 ${BG_COLOUR} font-bold`,
}));

export default function NewNFTRaffleModal({ onReset }) {
  const [step, _setStep] = useState(0);
  const [nftCollection, setNFTCollection] = useState(null);
  const [raffleOptions, setRaffleOptions] = useState();
  const [nftId, setNftId] = useState(null);
  const [nftMeta, setNFTMeta] = useState(null);
  const [userTokens, setUserTokens] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState({});

  const { address, nftRaffleController, erc721Contract } = useEVM();

  const { approve, loadingApprove, checkApproval } = useApproveERC721(
    { nftAddress: nftCollection?.value },
    (stp) => {
      if (stp === 1) {
        setStep(4);
      } else {
        setStep(5);
      }
    }
  );

  const setStep = (e) => {
    if (parseFloat(e) < 0) {
      onReset();
    } else {
      _setStep(e);
    }
  };
  const enableNext = () => {
    if (step === 0) {
      return nftCollection &&
        selectedCollection.nftCollection === nftCollection.value &&
        selectedCollection?.balance > 0
        ? true
        : false;
    }
    if (step === 1) {
      return nftId !== null ? true : false;
    }
    if (step === 2) {
      return (
        raffleOptions?.payToken &&
        raffleOptions?.price &&
        raffleOptions?.start &&
        raffleOptions?.end &&
        raffleOptions?.quantity
      );
    }
    return true;
  };

  useEffect(() => {
    if (address && nftCollection) {
      MyNFTTokens(address, nftCollection.value).then((d) => setUserTokens(d));
    }
  }, [address, nftCollection]);

  function renderChooseCollection() {
    return (
      <div
        style={{ minWidth: 300 }}
        className="flex gap-1.5 mt-4 mb-6 z-10 flex-col"
      >
        <span className="font-medium">Choose NFT collection</span>
        <div className="z-40" style={{ height: 37 }}>
          <div className="h-full z-40">
            <Dropdown
              arrowClosed={
                <img
                  alt="arrow"
                  className="transform origin-center rotate-90"
                  src={RightArrow}
                />
              }
              arrowOpen={
                <img
                  alt="arrow"
                  className="transform origin-center -rotate-90"
                  src={RightArrow}
                />
              }
              placeholder={"Collection"}
              options={options}
              onChange={(e) => {
                setNFTCollection(e);
                try {
                  erc721Contract?.instance
                    ?.attach(e.value)
                    ?.balanceOf(address)
                    .then((b) =>
                      setSelectedCollection({
                        balance: parseFloat(b.toString()),
                        nftCollection: e.value,
                      })
                    );
                } catch (error) {
                  setSelectedCollection({
                    balance: 0,
                    nftCollection: e.value,
                  });
                }
              }}
              value={nftCollection}
              className={`border cursor-pointer border-darkGray border-solid rounded ${BG_COLOUR} shadow-lg shadow-black`}
              controlClassName="px-2 pt-1 h-9 w-26 flex flex-row justify-between"
            />
          </div>
        </div>
        <span className="z-10">Balance: {selectedCollection.balance}</span>
      </div>
    );
  }

  function renderChooseNFT() {
    return (
      <div
        style={{ minWidth: 300 }}
        className="flex max-w-xs gap-1.5 mt-4 mb-6 z-10 flex-col"
      >
        <span className="font-medium">Choose prize NFT</span>
        <div
          style={{ maxHeight: 200 }}
          className="flex overflow-y-scroll gap-3 flex-wrap"
        >
          {userTokens?.map((d, index) => {
            return (
              <NftTokenCardd
                onClick={(i) => {
                  setNftId(i.tokenId);
                  setNFTMeta(i);
                }}
                key={index}
                data={d}
                selected={nftId?.toString()}
              />
            );
          })}
        </div>
      </div>
    );
  }

  async function handleCreateRaffle() {
    let toastId;
    setLoading(true);

    try {
      const transaction = await nftRaffleController?.instance?.createPool(
        raffleOptions?.payToken,
        nftCollection.value,
        nftId,
        1,
        parseEther(raffleOptions?.price, raffleOptions?.payToken),
        raffleOptions.start,
        raffleOptions.end,
        raffleOptions.quantity
      );
      toastId = loadingToast(`Creating raffle...`);
      nftRaffleController.instance.provider
        .waitForTransaction(transaction.hash)
        .then(async () => {
          dismissToast(toastId);
          setLoading(false);
          successToast("Raffle created!", {
            duration: 4000,
          });
        });
    } catch (error) {
      console.log(error);
      handleError(error);
      setLoading(false);
    }
  }

  function renderCheckoutNFT() {
    return (
      <div className="flex gap-10 flex-col">
        <div className="border w-72 p-1.5 border-purple text-sm rounded bg-purple bg-opacity-10">
          Raffles are decentralized. You can only cancel the raffle if no
          tickets have been sold.
        </div>
        <div className="flex flex-col">
          <span className="text-purple">
            {collectionMeta[nftCollection?.value]?.name}
          </span>
          <div className="flex items-center gap-2">
            <img alt="preview" className="w-10 h-10" src={nftMeta?.image} />
            <span className="font-medium">{nftMeta?.name}</span>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex gap-8">
            <div className="flex z-0 gap-1.5 flex-col">
              <span className="font-medium">Max ticket quantity:</span>
              <span className="font-thin">{raffleOptions?.quantity}</span>
            </div>
            <div className="flex z-0 gap-1.5 flex-col">
              <span className="font-medium">Ticket price:</span>
              <div className="bg-transparent flex outline-none font-thin rounded">
                <span>{raffleOptions?.price}</span>
                <span className="bg-dark bg-opacity-60 px-2 font-thin">
                  {payTokenMeta[raffleOptions?.payToken]?.name}
                </span>
              </div>
            </div>
          </div>

          <div className="flex gap-8">
            <div className="flex w-32 mr-3 gap-1.5 flex-col">
              <span className="font-medium">Start date:</span>
              <span className="font-thin">
                {new Date(raffleOptions?.start).getTime()}
              </span>
            </div>
            <div className="flex gap-1.5 flex-col">
              <span className="font-medium">End date:</span>
              <span className="font-thin">
                {new Date(raffleOptions?.end).getTime()}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function handleApprove() {
    approve();
  }

  function handleCreate() {
    checkApproval(address);
  }

  return (
    <div className="flex gap-8 py-5 px-4 flex-col">
      <span className="text-xl self-center font-bold">New NFT raffle</span>
      {step > 3 && (
        <Approve
          step={step}
          success="Raffle created!"
          loading={loadingApprove || loading}
          approve={"Approve"}
          action={"Create raffle"}
          applyStep1={handleApprove}
          applyStep2={handleCreateRaffle}
          approveStep={4}
          actionStep={5}
        />
      )}
      {step === 0 && renderChooseCollection()}
      {step === 1 && renderChooseNFT()}
      {step === 2 && (
        <NewRaffleOptionsModal
          data={raffleOptions}
          onChange={setRaffleOptions}
        />
      )}
      {step === 3 && renderCheckoutNFT()}

      <div className="flex z-0 self-center gap-2">
        {step < 3 && (
          <>
            <button
              onClick={() => setStep(step - 1)}
              className="bg-purple px-3 rounded"
            >
              Back
            </button>
            <button
              disabled={!enableNext()}
              onClick={() => setStep(step + 1)}
              className={cs("bg-purple border border-purple px-3 rounded", {
                "opacity-20": !enableNext(),
              })}
            >
              Next
            </button>
          </>
        )}
        {step === 3 && (
          <button
            onClick={() => handleCreate()}
            className={cs("bg-purple border border-purple px-3 rounded", {
              "opacity-20": !enableNext(),
            })}
          >
            Create
          </button>
        )}
      </div>
    </div>
  );
}
