import { useState } from "react";
import Filter from "./Filter";
import HorizontalSelector from "./HorizontalSelector";
import { payTokenMeta, ZERO_ADDRESS } from "../../helpers/payTokens";

export default function RaffleTypeFilter({ onApply, defaultOpen = null }) {
  const [type, setType] = useState(payTokenMeta[ZERO_ADDRESS]);

  return (
    <Filter label="Raffle type" defaultOpen={defaultOpen}>
      <div className="flex gap-5 py-3 pb-5 px-1 flex-col">
        <HorizontalSelector
          selectedValue={type}
          onValueChanged={(e) => {
            onApply(e);
            if (e === type) {
              setType(null);
            } else {
              setType(e);
            }
          }}
          options={["NFT", "Crypto"].map((type) => ({
            value: type === "NFT" ? "NFT" : "TOKEN",
            label: type,
          }))}
        />
      </div>
    </Filter>
  );
}
