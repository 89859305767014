import moment from "moment";
import { Link } from "react-router-dom";
import { getPoolURL, usePoolDisplayData } from "../helpers/pools";
import Address from "./Address";

function TopCards({ data }) {
  const { info: poolInfo } = usePoolDisplayData(data);

  return data ? (
    <Link
      to={getPoolURL(data)}
      className="flex z-40 bg-opacity-0 hover:shadow-3xl mt-rounded-lg w-60 bg-purple text-white  rounded flex-col"
    >
      <div className="p-2 rounded-t gap-6 flex flex-col w-full ">
        <img
          className="rounded-t -mb-4 z-30 h-44 object-contain"
          alt="raffle preview"
          src={poolInfo?.image}
        />
      </div>
      <div className="flex bg-purple bg-opacity-10 border-2 border-purple pt-8 pb-3 rounded-lg flex-col">
        <div className="flex  items-center -mt-0.5 px-4 justify-between">
          <span className="font-bold uppercase w-full text-center text-white">
            {poolInfo?.name}
          </span>
        </div>
        <span className="text-2xl font-bold text-center  text-purple">
          {data?.winners?.length > 0 && (
            <Address
              length={10}
              address={
                data?.winners?.length > 0 ? data?.winners[0].winnerAddress : "0"
              }
            />
          )}
          {data?.winners?.length <= 0 && <>Raffle not drawn</>}
        </span>

        <div
          style={{ height: "0.5px", backgroundColor: "rgb(117, 76, 203)" }}
          className=" my-3 w-full"
        />
        {data?.winners?.length > 0 && (
          <span className="text-white text-center text-2xl font-bold">
            Winner ID{data?.winners?.length > 1 ? "s: " : ": "}
            <span className="text-yellow">
              {data?.winners?.map((w) => w.winnerTokenId).join(",")}
            </span>
          </span>
        )}

        {data?.winners?.length <= 0 && (
          <Link
            to={getPoolURL(data)}
            className="outline-none self-center  h-8 font-bold text-lg text-white hover:text-white bg-purple  w-40  px-2 rounded-full text-center"
          >
            Draw raffle
          </Link>
        )}

        <div
          style={{ height: "0.5px", backgroundColor: "rgb(117, 76, 203)" }}
          className=" my-3 w-full"
        />
        <span className="text-sm self-start px-4 text-center">
          Ended:{" "}
          <span className=" w-full font-bold">
            {" "}
            {moment(new Date(data.end * 1000).toISOString()).format(
              "MMM Do YYYY, h:mm a"
            )}
          </span>
        </span>
      </div>
    </Link>
  ) : null;
}

export default TopCards;
