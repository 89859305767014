import { useEffect, useState } from "react";
import Datetime from "react-datetime";
import { payTokenMeta } from "../helpers/payTokens";

import ListingCoinDropdown from "./CoinDropdowns/ListingCoinDropdown";
import { options as paytokenDropdownOptionsUnfiltered } from "./CoinDropdowns/ListingCoinDropdown";
const paytokenDropdownOptions = paytokenDropdownOptionsUnfiltered.filter(o => o.hide !== true);

export default function NewRaffleOptionsModal({ data, onChange }) {
  const [payToken, setPayToken] = useState(paytokenDropdownOptions[0]);
  const [startTime, setStartTime] = useState(
    new Date(new Date().getTime() + 2 * 60 * 1000)
  );
  const [endTime, setEndTime] = useState(
    new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 10)
  );

  useEffect(() => {
    onChange({
      payToken: payToken.value,
      start: parseInt(startTime.getTime() / 1000),
      end: parseInt(endTime.getTime() / 1000),
      quantity: data?.quantity || null,
      price: data?.price || null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex gap-10 flex-col">
      <div className="flex flex-col gap-3">
        <div className="flex z-10 gap-1.5 flex-col">
          <span className="font-medium">Pay token:</span>

          <ListingCoinDropdown
            options={paytokenDropdownOptions}
            onChange={(e) => {
              setPayToken(e);
              onChange({ ...data, payToken: e.value });
            }}
            value={payToken}
          />
        </div>

        <div className="flex gap-8">
          <div className="flex z-0 gap-1.5 flex-col">
            <span className="font-medium">Max ticket quantity:</span>
            <input
              value={data?.quantity}
              onChange={(e) => onChange({ ...data, quantity: e.target.value })}
              className="bg-darkGray bg-opacity-30 w-48 border px-2 outline-none border-darkGray rounded"
              type={"number"}
            />
          </div>
          <div className="flex z-0 gap-1.5 flex-col">
            <span className="font-medium">Ticket price:</span>
            <div className="bg-transparent w-48 border flex outline-none border-darkGray rounded">
              <input
                value={data?.price}
                onChange={(e) => onChange({ ...data, price: e.target.value })}
                className="w-full px-2"
                type={"number"}
              />
              <span className="bg-dark bg-opacity-60 px-2 border-l border-darkGray">
                {payTokenMeta[data?.payToken]?.name}
              </span>
            </div>
          </div>
        </div>

        <div className="flex gap-8">
          <div className="flex w-48 gap-1.5 flex-col">
            <span className="font-medium">Start date:</span>
            <Datetime
              value={startTime}
              className={
                "calendarAboveInput w-full text-sm outline-none bg-dark border-darkGray border rounded-md pl-2 p-0.5 py-1.5 self-end"
              }
              onChange={(val) => {
                onChange({
                  ...data,
                  start: parseInt(val.toDate().getTime() / 1000),
                });
                setStartTime(val.toDate());
              }}
              inputProps={{
                onKeyDown: (e) => e.preventDefault(),
              }}
              closeOnSelect
              isValidDate={(cur) =>
                cur.valueOf() > startTime.getTime() - 1000 * 60 * 60 * 23 &&
                cur.valueOf() < startTime.getTime() + 31 * 1000 * 60 * 60 * 23
              }
            />
          </div>
          <div className="flex w-48 gap-1.5 flex-col">
            <span className="font-medium">End date:</span>
            <Datetime
              value={endTime}
              className={
                "calendarAboveInput w-full text-sm outline-none bg-dark border-darkGray border rounded-md pl-2 p-0.5 py-1.5 self-end"
              }
              onChange={(val) => {
                onChange({
                  ...data,
                  end: parseInt(val.toDate().getTime() / 1000),
                });
                setEndTime(val.toDate());
              }}
              inputProps={{
                onKeyDown: (e) => e.preventDefault(),
              }}
              closeOnSelect
              isValidDate={(cur) =>
                cur.valueOf() > startTime.getTime() - 1000 * 60 * 60 * 23 &&
                cur.valueOf() < startTime.getTime() + 31 * 1000 * 60 * 60 * 23
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
