import { useEffect, useState } from "react";
import Address from "../../components/Address";
import { UsersMostVolume } from "../../graphql/api";
import { formatEther } from "../../helpers/payTokens";
import { getPoolURL } from "../../helpers/pools";

function Row({ index, data }) {
  return (
    <div href={getPoolURL(data)} className={"flex  p-1.5 rounded py-1.5"}>
      <span className="w-44">
        {index}. <Address address={data.id} ellipsis={true} length={7} />{" "}
      </span>{" "}
      <div className="flex gap-1 items-end">
        {parseFloat(formatEther(data.volume)).toFixed(0)}{" "}
        <span className="text-sm">EVMOS</span>
      </div>
    </div>
  );
}

export default function UsersVolume() {
  const [data, setData] = useState([]);

  useEffect(() => {
    UsersMostVolume().then((d) => {
      setData(d);
    });
  }, []);

  return (
    <div className="flex gap-4 flex-col">
      <span className="text-2xl font-semibold text-purple">
        Most Ticket Volume
      </span>
      <div className="border gap-1 flex flex-col border-purple p-1.5 py-0 rounded">
        {data?.map((d, index) => (
          <Row index={index + 1} key={index} data={d} />
        ))}
      </div>
    </div>
  );
}
