import { useEffect, useState } from "react";
import debounce from "lodash/debounce";

export function useEffectWithDeepEquality(func, sensitivityList) {
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(func, [JSON.stringify(sensitivityList)]);
}

export function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    const debouncedHandleResize = debounce(handleResize, 500);
    window.addEventListener("resize", debouncedHandleResize);
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });
  return width;
}

export function useIsMobile() {
  const width = useWindowWidth();
  return width <= 768;
}
