import { isProd } from "./env";

const testCollections = {
  "0xfa870dc94b617584d92646ae950eb8290d8ed8e8": {
    name: "Evmos Domains",
    index: 0,
    contract: "0xfa870dc94b617584d92646ae950eb8290d8ed8e8",
    description:
      "Evmos Domains lets you register your own human-readable .evmos domain names that link, or 'resolve', to addresses on the Evmos blockchain.",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+Domains/banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+Domains/profile.jpeg",
    socials: [
      {
        type: "web",
        value: "https://www.orbitalapes.com/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/OrbitalApes",
      },
      {
        type: "discord",

        value: "https://discord.com/invite/orbitalapes",
      },
    ],
  },
  "0x0e4cf372dfa92a70095e503a4477d043b3e6818a": {
    name: "Orbital Apes",
    index: 0,
    contract: "0x0e4cf372dfa92a70095e503a4477d043b3e6818a",
    description:
      "Orbital Apes is a collection of 10,000 NFTS coming to EVMOS. Each Orbital Ape created from a high quality 3D generation consisting of over 200 different attributes and 4 unique generations. Join our community to expand your network, earn rewards, and play exclusive games.",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Orbital+Apes/Banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Orbital+Apes/Profile.jpg",
    socials: [
      {
        type: "web",
        value: "https://www.orbitalapes.com/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/OrbitalApes",
      },
      {
        type: "discord",

        value: "https://discord.com/invite/orbitalapes",
      },
    ],
    category: ["3D", "PFP", "Utility", ""],
  },
  "0x1ce489ebe654f686c91c5cecb570b881c8260640": {
    name: "Honorary Orbital Apes",
    index: 1,
    contract: "0x1ce489ebe654f686c91c5cecb570b881c8260640",
    description: "Honorary members of Orbital Apes",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Honorary+Orbital+Apes/Banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Honorary+Orbital+Apes/profile.png",
    socials: [
      {
        type: "web",
        value: "https://www.orbitalapes.com/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/OrbitalApes",
      },
      {
        type: "discord",

        value: "https://discord.com/invite/orbitalapes",
      },
    ],
    category: ["3D", "PFP"],
  },
  "0xa2801446e4f0b50e87e421b3530a1d905ce35cc7": {
    name: "Bored Ape Yacht Club",
    index: 0,
    contract: "0xa2801446e4f0b50e87e421b3530a1d905ce35cc7",
    description:
      "The Bored Ape Yacht Club is a collection of 10,000 unique Bored Ape NFTs— unique digital collectibles living on the Ethereum blockchain. Your Bored Ape doubles as your Yacht Club membership card, and grants access to members-only benefits, the first of which is access to THE BATHROOM, a collaborative graffiti board.",
    bannerURL:
      "https://lh3.googleusercontent.com/i5dYZRkVCUK97bfprQ3WXyrT9BnLSZtVKGJlKQ919uaUB0sxbngVCioaiyu9r6snqfi2aaTyIvv6DHm4m2R3y7hMajbsv14pSZK8mhs=h600",
    imageURL:
      "https://lh3.googleusercontent.com/Ju9CkWtV-1Okvf45wo8UctR-M9He2PjILP0oOvxE89AyiPPGtrR3gysu1Zgy0hjd2xKIgjJJtWIc0ybj4Vd7wv8t3pxDGHoJBzDB=s130",
    socials: [
      {
        type: "web",
        value: "https://twitter.com",
      },
      {
        type: "twitter",
        value: "https://twitter.com",
      },
      {
        type: "discord",

        value: "https://twitter.com",
      },
      {
        type: "telegram",
        value: "https://twitter.com",
      },
    ],
  },
};

const mainnetCollections = {
  "0x4c275ade386af17276834579b1a68146cef3c770": {
    name: "Orbital Apes",
    index: 0,
    contract: "0x4c275ade386af17276834579b1a68146cef3c770",
    description:
      "Orbital Apes is a collection of 10,000 NFTS coming to EVMOS. Each Orbital Ape created from a high quality 3D generation consisting of over 200 different attributes and 4 unique generations. Join our community to expand your network, earn rewards, and play exclusive games.",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Orbital+Apes/Banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Orbital+Apes/Profile.jpg",
    socials: [
      {
        type: "web",
        value: "https://www.orbitalapes.com/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/OrbitalApes",
      },
      {
        type: "discord",

        value: "https://discord.com/invite/orbitalapes",
      },
    ],
    category: ["3D", "PFP", "Utility"],
  },
  "0xabbaa322a763b36587e3f63e46a81deacb2957a7": {
    name: "Evmos Domains",
    index: 2,
    contract: "0xabbaa322a763b36587e3f63e46a81deacb2957a7",
    description:
      "Evmos Domains lets you register your own human-readable .evmos domain names that link, or 'resolve', to addresses on the Evmos blockchain.",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+Domains/banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Evmos+Domains/profile.jpeg",
    socials: [
      {
        type: "web",
        value: "https://evmos.domains/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/EvmosDomains",
      },
      {
        type: "discord",
        value: "https://chat.evmos.domains/",
      },
      {
        type: "telegram",
        value: "https://t.me/the_power_of_the_rings",
      },
    ],
    category: ["Domains"],
  },
  "0xcce4071459c70465428e1f03d0f381b4f4ea04af": {
    name: "Honorary Orbital Apes",
    index: 1,
    contract: "0xcce4071459c70465428e1f03d0f381b4f4ea04af",
    description: "Honorary members of Orbital Apes",
    bannerURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Honorary+Orbital+Apes/Banner.png",
    imageURL:
      "https://orbital-apes-public.s3.amazonaws.com/orbitalApesMarket/collections/Honorary+Orbital+Apes/profile.png",
    socials: [
      {
        type: "web",
        value: "https://www.orbitalapes.com/",
      },
      {
        type: "twitter",
        value: "https://twitter.com/OrbitalApes",
      },
      {
        type: "discord",

        value: "https://discord.com/invite/orbitalapes",
      },
    ],
    category: ["3D", "PFP"],
  },
};

export const collectionMeta = isProd ? mainnetCollections : testCollections;
