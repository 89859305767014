export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: "rgba(0,0,0,0)",
    color: "#fff",
    borderColor: "#8454EB",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: "#8454EB",
    },
    borderWidth: "1px",
    backgroundColor: "#1E1D2D!important",
    zIndex: 1000,
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingLeft: "16px",
    paddingRight: "16px",
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: "0px",
    borderWidth: "1px",
    borderColor: "#8454EB",
    "&:hover": {
      borderColor: "#8454EB",
    },
    backgroundColor: "#1E1D2D!important",
    color: "#FFF",
    zIndex: 1000,
    paddingLeft: "5px",
    paddingRight: "5px",
  }),
  menuList: (provided, state) => ({
    ...provided,
    zIndex: 1000,
  }),
  option: (provided, state) => ({
    ...provided,
    color: "white",
    backgroundColor: "#1E1D2D!important",
    "&:hover": {
      backgroundColor: "#2C2549!important",
    },
    cursor: "pointer",
    zIndex: 1000,
  }),
  multiValueRemove: (provided, state) => {
    return {
      ...provided,
      color: "#000",
      "&:hover": {
        backgroundColor: "#B2B2B2",
        color: "#000",
      },
      zIndex: 1000,
    };
  },
  singleValue: (provided, state) => ({
    ...provided,
    color: "white",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: "0 6px",
    zIndex: 1000,
    color: "white",
  }),
  placeholder: (provided, state) => ({
    color: "white",
    zIndex: 1000,
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
    zIndex: 1000,
  }),
  indicatorSeparator: (state) => ({
    display: "none",
    zIndex: 1000,
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    zIndex: 1000,
  }),
};
