import { useEffect, useState } from "react";
import Address from "../../components/Address";
import { HighestPlayersPools } from "../../graphql/api";
import { getPoolURL, usePoolDisplayData } from "../../helpers/pools";
import cs from "classnames";

function Row({ index, data }) {
  const { info: poolInfo } = usePoolDisplayData(data);

  return (
    <a
      href={getPoolURL(data)}
      className={cs("flex  p-3 rounded py-1.5", {
        "hover:bg-purple bg-purple bg-opacity-10 hover:bg-opacity-20":
          data?.type === "TOKEN",
        "hover:bg-pink bg-pink bg-opacity-10 hover:bg-opacity-20":
          data?.type === "NFT",
      })}
    >
      <span className="w-44">
        {index}. {poolInfo?.name}
      </span>{" "}
      <span className="w-40">
        {" "}
        <Address address={data.creator} ellipsis={true} length={7} />{" "}
      </span>{" "}
      {data?.playerCount} players
    </a>
  );
}

export default function RafflesPlayers() {
  const [data, setData] = useState([]);

  useEffect(() => {
    HighestPlayersPools().then((d) => {
      setData(d);
    });
  }, []);

  return (
    <div className="flex gap-4 flex-col">
      <span className="text-2xl font-semibold text-purple">
        Raffles With Most Unique Players
      </span>
      <div className="border gap-1 flex flex-col border-purple rounded p-1.5">
        {data?.map((d, index) => (
          <Row index={index + 1} key={index} data={d} />
        ))}
      </div>
    </div>
  );
}
