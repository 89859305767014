import { getName } from "../helpers/address";
import { useEffect, useState } from "react";
import { toASCII } from "@oozcitak/uts46";

export default function Address({ address, length, ellipsis }) {
  const [name, setName] = useState(null);

  function formatAddress() {
    return ellipsis
      ? `${address?.substring(0, length)}...${address?.substring(
          42 - length,
          42
        )}`
      : address?.substring(0, length);
  }

  useEffect(() => {
    (async () => {
      if (address && length) {
        const _name = await getName(address, length);
        const __name = toASCII(_name??"", {useStd3ASCII: true, transitional: false})
        setName(__name?.substring(0, 19));
      }
    })();
  }, [address, length, name]);

  return <>{`${name ? name : formatAddress()}`}</>;
}
