import toast from "react-hot-toast";

export function newToast(text) {
  toast(text);
}

export function dismissToast(id) {
  toast.dismiss(id);
}

export function loadingToast(text) {
  return toast.loading(text);
}

export function successToast(text, options) {
  toast.success(text, options);
}

export function errorToast(text) {
  toast.error(text);
}
