import { useState } from "react";
import { dismissToast, loadingToast, successToast } from "../components/Toasts";
import { handleError } from "../helpers/errors";
import { getPoolId } from "../helpers/pools";
import { useEVM } from "../hooks/EVMhook";

export default function SetSeed({ data }) {
  const [loading, setLoading] = useState(false);

  const { getController } = useEVM();

  async function handleSetSeed() {
    const mintService = await getController(data);
    let toastId;
    try {
      setLoading(true);
      const transaction = await mintService?.instance?.setSeedRound(
        getPoolId(data)
      );
      toastId = loadingToast(`Setting seed...`);

      mintService.instance.provider
        .waitForTransaction(transaction.hash)
        .then(async () => {
          dismissToast(toastId);
          setLoading(false);
          successToast("Seed set successfully", {
            duration: 4000,
          });
        });
    } catch (error) {
      console.log(error);
      handleError(error);
      setLoading(false);
    }
  }
  return (
    <div className="flex gap-1 flex-col">
      <span className="text-xl font-semibold">This raffle has ended</span>
      <span className="font-thin">Set a random seed to draw a winner</span>
      <button
        disabled={loading}
        onClick={() => handleSetSeed()}
        className="outline-none h-8 font-bold text-lg text-white hover:text-white bg-purple  w-40  px-2 rounded-md"
      >
        {loading && "Loading.."}
        {!loading && <>{`Set seed`}</>}
      </button>
    </div>
  );
}
