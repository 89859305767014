import InfiniteScroll from ".//infinite_scroll/InfiniteScroll";
import { LISTED_ITEMS_PER_PAGE } from "../graphql/api";

let RenderScrollComponent = function ({
  fetchPage,
  renderData,
  renderEmptyState,
  pageStart,
  hasNextPage: propsHasNextPage,
  onDataChanged,
  loader,
  threshold,
  sensitivityListForPaginationReset,
}) {
  return (
    <InfiniteScroll
      renderData={renderData}
      renderEmptyState={renderEmptyState}
      renderLoader={() => loader}
      threshold={threshold}
      queryData={sensitivityListForPaginationReset}
      fetchPage={async ({ pageNum, queryData }) => {
        const pageData = await fetchPage(pageNum + pageStart);
        const hasNextPage = propsHasNextPage
          ? propsHasNextPage(pageData)
          : pageData?.length === LISTED_ITEMS_PER_PAGE;
        return { pageData, hasNextPage };
      }}
      onDataChanged={onDataChanged}
    />
  );
};

export default RenderScrollComponent;
