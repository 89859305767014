import { useHistory } from "react-router-dom";
import cs from "classnames";
import Logo from "../assets/cards.png";
import x from "../assets/components/x.svg";
import rumble from "../assets/Rumble.png";

function MobileHeader({ setShow }) {
  const history = useHistory();

  return (
    <div
      className={cs(
        "z-50 bg-dark fixed top-0 bottom-0 flex flex-col left-0 right-0"
      )}
    >
      <button
        onClick={() => setShow(false)}
        className="outline-none z-40 self-end justify-self-end pr-8 pt-8 text-xl"
      >
        <img alt="x" className="w-6 cursor-pointer " src={x} />
      </button>
      <div className="flex pt-20 -mt-10 flex-col justify-start h-full items-center">
        <div className="flex pb-14 flex-col">
          <div className="flex items-center gap-2">
            <img
              onClick={() => {
                history.push("/");
                setShow(false);
              }}
              alt="logo"
              className=" cursor-pointer h-10"
              src={Logo}
            />
            <img
              onClick={() => {
                history.push("/");
                setShow(false);
              }}
              alt="logo"
              className=" cursor-pointer h-10"
              src={rumble}
            />
          </div>
        </div>
        <span
          onClick={() => {
            history.push("/mint");
            setShow(false);
          }}
          className="mt-4 hover:text-purple text-white font-medium text-xl cursor-pointer"
        >
          Home
        </span>
        <span
          onClick={() => {
            history.push("/winners");
            setShow(false);
          }}
          className="mt-4 hover:text-purple text-white font-medium text-xl cursor-pointer"
        >
          Past Raffles
        </span>
        <span
          onClick={() => {
            history.push("/profile");
            setShow(false);
          }}
          className="mt-4 hover:text-purple text-white font-medium text-xl cursor-pointer"
        >
          Profile
        </span>
      </div>
    </div>
  );
}

export default MobileHeader;
