import "react-datetime/css/react-datetime.css";

import TopBanner from "../components/TopBanner";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TopPools from "./HOME/TopPools";
import AutoPools from "./HOME/AutoPools";
import CampaignSwitch from "../components/CampaignSwitch";
import Pools from "./HOME/pools";
import Footer from "./Footer";
import { useState } from "react";

function Home() {
  const [campaign, setCampaign] = useState(0);

  function renderCampaign() {
    if (campaign === 0) return <TopPools />;
    else if (campaign === 1) return <AutoPools></AutoPools>;
  }

  return (
    <div style={{}} id="homediv" className="bg-dark">
      <div className="w-full z-40 relative text-dark bg-opacity-0 bg-purple">
        <div className="flex flex-col items-center justify-center px-5 md:px-10 ">
          <TopBanner />
          <CampaignSwitch campaign={campaign} setCampaign={setCampaign} />
          {renderCampaign()}
          <div
            style={{ minHeight: 800 }}
            className="flex bg-dark  flex-col items-center w-full"
          >
            <Pools />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
