import { useState } from "react";
import cs from "classnames";
import UpArrow from "../../assets/icons/arrow-up-svgrepo-com.svg";
import DownArrow from "../../assets/icons/arrow-down-svgrepo-com.svg";

export default function Filter({ label, defaultOpen = true, children }) {
  const [open, setOpen] = useState(true);

  return (
    <div
      className="flex bg-purple border-b border-purpleBorder bg-opacity-0 w-full flex-col"
      key={label}
    >
      <div
        onClick={() => setOpen(!open)}
        className={cs("flex justify-between cursor-pointer   py-2  px-3")}
      >
        <span className="font-semibold text-lg">{label}</span>
        <img
          className="w-5"
          alt="arrow indicator"
          src={open ? UpArrow : DownArrow}
        />
      </div>
      {open && <div className="px-2">{children}</div>}
    </div>
  );
}
