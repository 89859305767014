import { errorToast } from "../components/Toasts";

export function handleError(error) {
  if (error.code === 4001 && error.message) return errorToast(error.message);
  if (error?.data?.message) {
    if (error?.data?.message.includes("not been reached")) {
      errorToast(
        "Random seed offset not met. Please try again in a few minutes"
      );
    } else {
      errorToast(error?.data?.message);
    }
  } else {
    errorToast("Something Went Wrong!");
  }
}
