import RenderScrollComponent from "../../components/EasyInfiniteScroll";
import { useIsMobile } from "../../helpers/Hooks";
import cs from "classnames";
import FilterPanel from "../../components/filters/FilterPanel";
import { useEffect, useState } from "react";

function FilterButton({ onClick, numFilters }) {
  return (
    <button
      className="bg-purple text-white font-semibold rounded-full py-3 px-5 cursor-hand flex align-center"
      onClick={onClick}
    >
      Filters
    </button>
  );
}

function SlideInFullscreenOverlay({ isExpanded, children }) {
  useEffect(() => {
    if (isExpanded) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
  }, [isExpanded]);
  return (
    <div
      className={cs(
        "fixed w-screen p-7 z-50 h-screen top-0 left-0 z-9999 overflow-y-scroll bg-dark text-white transform transition-transform transition-slowest ease-in-out",
        {
          "-translate-x-full": !isExpanded,
          "translate-x-0": isExpanded,
        }
      )}
    >
      {children}
    </div>
  );
}

function MobileView({
  filterComponentList,
  activeFilters,
  searchBar,
  sortingDropdownMenu,
  infiniteScroll,
}) {
  const [isFiltersExpanded, setIsFiltersExpanded] = useState(false);
  return (
    <div className="flex relative w-full">
      <SlideInFullscreenOverlay isExpanded={isFiltersExpanded}>
        <div className="p-8 border rounded-md bg-purple bg-opacity-5 border-purple">
          <div className="flex justify-end py-4 pt-0">
            <button onClick={() => setIsFiltersExpanded(false)}>Done</button>
          </div>
          {filterComponentList}
        </div>
      </SlideInFullscreenOverlay>

      <div className="flex-grow w-full items-center flex flex-col z-0">
        <div className="flex w-full boder-b border-darkGray justify-end mx-1 py-5 sticky -top-1 z-10 bg-dark gap-3">
          {sortingDropdownMenu}
        </div>
        {infiniteScroll}
        <div className="sticky bottom-0 z-10 w-full">
          <div className="absolute w-full h-full">
            <div className="fixed bottom-0 left-0 w-full z-10">
              <div className="mb-6 flex justify-center ">
                <FilterButton onClick={() => setIsFiltersExpanded(true)} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function DesktopView({
  filterComponentList,
  activeFilters,
  searchBar,
  sortingDropdownMenu,
  infiniteScroll,
}) {
  return (
    <div className="flex max-w-7xl w-full relative gap-8 h-full">
      <div
        className="block  sticky"
        style={{
          top: 50,
          height: window.innerHeight - 50,
        }}
      >
        <FilterPanel>{filterComponentList}</FilterPanel>
      </div>

      <div className="flex-grow">
        <div className="flex md:flex-row flex-col md:justify-end justify-between p-5 pt-4 sticky z-10 bg-dark gap-5">
          {/* <div className="flex flex-col gap-5 flex-grow">
            {searchBar}
            {activeFilters}
          </div> */}
          {sortingDropdownMenu}
        </div>
        {infiniteScroll}
      </div>
    </div>
  );
}

export default function Scroll({
  onDataChanged,
  renderData,
  renderEmptyState,
  fetchPage,
  sensitivityListForPaginationReset,
  sortingDropdownMenu,
  filterComponentList,
}) {
  const isMobile = useIsMobile();

  const infiniteScroll = (
    <RenderScrollComponent
      pageStart={1}
      loader={
        <div
          className={cs("flex w-full justify-center", {
            "py-24": isMobile,
            "py-12": !isMobile,
          })}
        >
          <span>Loading..</span>
        </div>
      }
      onDataChanged={onDataChanged}
      threshold={800}
      renderData={renderData}
      renderEmptyState={renderEmptyState}
      fetchPage={fetchPage}
      sensitivityListForPaginationReset={sensitivityListForPaginationReset}
    />
  );
  return (
    <div className="text-white w-full flex justify-center">
      {isMobile ? (
        <MobileView
          filterComponentList={filterComponentList}
          // activeFilters={activeFilters}
          // searchBar={searchBar}
          sortingDropdownMenu={sortingDropdownMenu}
          infiniteScroll={infiniteScroll}
        />
      ) : (
        <DesktopView
          filterComponentList={filterComponentList}
          // activeFilters={activeFilters}
          // searchBar={searchBar}
          sortingDropdownMenu={sortingDropdownMenu}
          infiniteScroll={infiniteScroll}
        />
      )}
    </div>
  );
}
