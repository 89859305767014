import { useEffect, useState } from "react";
import NewRaffleOptionsModal from "./NewRaffleOptionsModal";
import { options as paytokenDropdownOptionsUnfiltered } from "./CoinDropdowns/ListingCoinDropdown";

import { collectionMeta } from "../helpers/collectionInfo";
import cs from "classnames";
import {
  parseEther,
  payTokenMeta,
  formatEther,
  ZERO_ADDRESS,
} from "../helpers/payTokens";
import { useEVM } from "../hooks/EVMhook";
import ListingCoinDropdown from "./CoinDropdowns/ListingCoinDropdown";
import Approve from "./Approve";
import { useApproveERC20 } from "../hooks/marketTransactions";
import { dismissToast, loadingToast, successToast } from "./Toasts";
import { handleError } from "../helpers/errors";
import { isSameAddress } from "../helpers/address";
import { ethers } from "ethers";
import moment from "moment";

const paytokenDropdownOptions = paytokenDropdownOptionsUnfiltered;

const _options = Object.keys(collectionMeta).map((token) => {
  return {
    wrapped: collectionMeta[token].wrapped,
    erc: collectionMeta[token].erc,
    value: token,
    min: collectionMeta[token].min,
    label: (
      <div className="flex items-center gap-1">
        <img
          className={` w-4 h-4 rounded-full`}
          alt="eth"
          src={collectionMeta[token].imageURL}
        />
        <span>{collectionMeta[token].name}</span>
      </div>
    ),
  };
});
const BG_COLOUR = "bg-darkGray";

export const options = _options.map((o) => ({
  ...o,
  className: `border-t hover:bg-dark cursor-pointer border-dark border-solid mx-2 p-3 py-1.5 ${BG_COLOUR} font-bold`,
}));

export default function NewERC20ReffleModal({ onReset }) {
  const [step, _setStep] = useState(0);
  const [raffleOptions, setRaffleOptions] = useState();
  const [prizeToken, setPrizeToken] = useState(paytokenDropdownOptions[0]);
  const [prizeBalance, setPrizeBalance] = useState(0);
  const [amount, setAmount] = useState(payTokenMeta[prizeToken?.value]?.min);
  const [loading, setLoading] = useState(false);

  const { address, erc20Service, tokenRaffleController } = useEVM();

  const {
    loading: loadingApprove,
    approve,
    checkApproval,
  } = useApproveERC20((step) => {
    if (step === 1) {
      setStep(3);
    } else {
      setStep(4);
    }
  });

  const setStep = (e) => {
    if (parseFloat(e) < 0) {
      onReset();
    } else {
      _setStep(e);
    }
  };
  const enableNext = () => {
    if (step === 0) {
      return parseFloat(prizeBalance) > 0 &&
        parseFloat(prizeBalance) > parseFloat(amount) &&
        parseFloat(amount) > 0 &&
        parseFloat(amount) >= payTokenMeta[prizeToken?.value]?.min
        ? true
        : false;
    }
    if (step === 1) {
      return (
        raffleOptions?.payToken &&
        raffleOptions?.price &&
        raffleOptions?.start &&
        raffleOptions?.end &&
        raffleOptions?.quantity
      );
    }
    if (step === 2) return true;
  };

  async function handleCreateRaffle() {
    let toastId;
    setLoading(true);

    try {
      let transaction;
      if (isSameAddress(ZERO_ADDRESS, prizeToken?.value)) {
        transaction = await tokenRaffleController?.instance?.createPool(
          raffleOptions?.payToken,
          prizeToken?.value,
          parseEther(amount.toString()),
          parseEther(raffleOptions?.price, raffleOptions?.payToken),
          raffleOptions.start,
          raffleOptions.end,
          raffleOptions.quantity,
          { value: parseEther(amount.toString()) }
        );
      } else {
        transaction = await tokenRaffleController?.instance?.createPool(
          raffleOptions?.payToken,
          prizeToken?.value,
          parseEther(amount.toString(), prizeToken?.value),
          parseEther(raffleOptions?.price, raffleOptions?.payToken),
          raffleOptions.start,
          raffleOptions.end,
          raffleOptions.quantity
        );
      }
      toastId = loadingToast(`Creating raffle...`);
      tokenRaffleController.instance.provider
        .waitForTransaction(transaction.hash)
        .then(async () => {
          dismissToast(toastId);
          setLoading(false);
          successToast("Raffle created!", {
            duration: 4000,
          });
        });
    } catch (error) {
      console.log(error);
      handleError(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (address && prizeToken && erc20Service?.instance) {
      setAmount(payTokenMeta[prizeToken?.value]?.min);
      if (isSameAddress(ZERO_ADDRESS, prizeToken.value)) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        provider.getBalance(address).then((evmosBal) => {
          setPrizeBalance(parseFloat(evmosBal.toString()) / 10 ** 18);
        });
      } else {
        erc20Service?.instance
          ?.attach(prizeToken.value)
          ?.balanceOf(address)
          .then((b) => {
            setPrizeBalance(
              parseFloat(formatEther(b, prizeToken.value)).toFixed(2)
            );
          });
      }
    }
  }, [address, erc20Service?.instance, prizeToken]);

  function renderChoosePrizeToken() {
    return (
      <div
        style={{ minWidth: 300 }}
        className="flex gap-3 mt-4 mb-6 items-start flex-col"
      >
        <div className="flex gap-1.5  z-10 flex-col">
          <span className="font-medium self-start">Choose prize token</span>
          <ListingCoinDropdown
            options={paytokenDropdownOptions}
            onChange={(e) => {
              setPrizeToken(e);
            }}
            value={prizeToken}
          />
        </div>
        <div className="flex z-0 flex-col gap-1">
          <span className="font-medium">
            {payTokenMeta[prizeToken?.value]?.name} Amount:
          </span>
          <input
            onChange={(e) => {
              setAmount(e.target.value);
            }}
            value={amount}
            className="px-2"
            type="number"
          />
        </div>
        <div className="flex pl-1 text-sm gap-1">
          <span className="font-medium">Balance:</span>
          <span>{prizeBalance.toString()}</span>
        </div>
        <div className="flex border border-purple rounded w-full bg-purple bg-opacity-10 py-1.5 justify-center text-sm gap-1">
          <span className="font-medium">Min prize amount:</span>
          <span>
            {payTokenMeta[prizeToken?.value].min?.toString()}{" "}
            {payTokenMeta[prizeToken?.value]?.name}
          </span>
        </div>
      </div>
    );
  }

  function renderCheckoutERC() {
    return (
      <div className="flex gap-10 flex-col">
        <div className="flex gap-1 flex-col">
          <div className="border w-72 p-1.5 border-purple text-sm rounded bg-purple bg-opacity-10">
            Raffles are decentralized. You can only cancel the raffle if no
            tickets have been sold.
          </div>
          <span className="text-purple">Prize token</span>
          <div className="flex items-center gap-2">
            <img
              alt="paytoken"
              className="w-7 h-7"
              src={payTokenMeta[prizeToken?.value].image}
            />
            <span className="font-medium">{amount}</span>
            <span className="font-thin">
              ({payTokenMeta[prizeToken?.value].name})
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex gap-8">
            <div className="flex z-0 gap-1.5 flex-col">
              <span className="font-medium">Max ticket quantity:</span>
              <span className="font-thin">{raffleOptions?.quantity}</span>
            </div>
            <div className="flex z-0 gap-1.5 flex-col">
              <span className="font-medium">Ticket price:</span>
              <div className="bg-transparent flex outline-none font-thin rounded">
                <span>{raffleOptions?.price}</span>
                <span className="bg-dark bg-opacity-60 px-2 font-thin">
                  {payTokenMeta[raffleOptions?.payToken]?.name}
                </span>
              </div>
            </div>
          </div>

          <div className="flex gap-8">
            <div className="flex w-32 mr-3 gap-1.5 flex-col">
              <span className="font-medium">Start date:</span>
              <span className="font-thin">
                {moment(
                  new Date(raffleOptions?.start * 1000).toISOString()
                ).format("MMM Do YYYY, h:mm a")}
              </span>
            </div>
            <div className="flex w-32 gap-1.5 flex-col">
              <span className="font-medium">End date:</span>
              <span className="font-thin">
                {moment(
                  new Date(raffleOptions?.end * 1000).toISOString()
                ).format("MMM Do YYYY, h:mm a")}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function handleApprove() {
    approve(prizeToken?.value);
  }

  function handleCreate() {
    checkApproval(address, prizeToken?.value);
  }

  return (
    <div className="flex gap-8 justify-between py-5 px-4 flex-col">
      <span className="text-xl self-center font-bold">New ERC-20 raffle</span>

      {step === 0 && renderChoosePrizeToken()}
      {step === 1 && (
        <NewRaffleOptionsModal
          data={raffleOptions}
          onChange={setRaffleOptions}
        />
      )}
      {step === 2 && renderCheckoutERC()}
      {step > 2 && (
        <Approve
          step={step}
          success="Raffle created!"
          loading={loadingApprove || loading}
          approve={"Approve"}
          action={"Create raffle"}
          applyStep1={handleApprove}
          applyStep2={handleCreateRaffle}
          approveStep={3}
          actionStep={4}
        />
      )}
      <div className="flex z-0 self-center gap-2">
        {step < 2 && (
          <>
            <button
              onClick={() => setStep(step - 1)}
              className="bg-purple px-3 rounded"
            >
              Back
            </button>
            <button
              onClick={() => setStep(step + 1)}
              disabled={!enableNext()}
              className={cs("bg-purple border border-purple px-3 rounded", {
                "opacity-20": !enableNext(),
              })}
            >
              Next
            </button>
          </>
        )}

        {step === 2 && (
          <button
            onClick={() => handleCreate()}
            className={cs("bg-purple border border-purple px-3 rounded", {
              "opacity-20": !enableNext(),
            })}
          >
            Create
          </button>
        )}
      </div>
    </div>
  );
}
