// https://usehooks.com/useLocalStorage/

import { useState } from "react";
import { fetchData } from "../lambda/lambda";
import { payTokenMeta } from "./payTokens";

// Hook
export function useTokenPrice() {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState({});

  const getValue = (key) => {
    if (storedValue[key]) {
      return storedValue[key];
    }
    if (typeof window === "undefined") {
      return null;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      const lastUpdate = window.localStorage.getItem("lastUpdate");
      const currentTime = parseInt(new Date().getTime() / 1000);
      if (!lastUpdate) return null;
      if (currentTime - parseInt(lastUpdate) > 43200) {
        return null;
      }
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : null;
    } catch (error) {
      // If error also return initialValue
      return null;
    }
  };
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (key, value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
        const currentTime = parseInt(new Date().getTime() / 1000);
        window.localStorage.setItem("lastUpdate", currentTime);

        setStoredValue({ ...storedValue, key: value });
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  const base_url = "https://api.dexscreener.com/latest/dex/pairs/evmos/";

  async function getPrice(key) {
    const pair = payTokenMeta[key].pair;
    const data = await fetchData(base_url + pair);
    return data?.pair?.priceNative;
  }

  const getTokenPrice = async (tokenAddress) => {
    let price = getValue(tokenAddress);
    if (!price) {
      price = await getPrice(tokenAddress);
      setValue(tokenAddress, price);
    }
    return price;
  };

  return { getTokenPrice };
}
