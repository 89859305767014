import React from "react";

import cs from "classnames";

export default function FilterPanel({ children }) {
  return (
    <div
      style={{ maxHeight: "90vh" }}
      className={cs(
        "flex transition-width px-0  p-4 sticky top-10  transition-slowest ease-in-out duration-500 flex-col h-full",
        {
          "w-72 min-w-72": true,
        }
      )}
    >
      <div className="flex flex-col border border-purple rounded-md">
        <div
          className={cs(
            "flex border-purple  bg-purple bg-opacity-10 border-b py-2 justify-between px-3"
          )}
        >
          <span
            className={cs("font-semibold text-lg pr", {
              // flex: open,
              // hidden: !open,
            })}
          >
            Filters
          </span>
        </div>

        <div
          className={cs(
            "flex flex-col bg-purple bg-opacity-10 overflow-y-scroll",
            {
              flex: true,
            }
          )}
        >
          {React.Children.map(children, (childFilter) => (
            <div className="flex w-full   justify-center">{childFilter}</div>
          ))}
        </div>
      </div>
    </div>
  );
}
