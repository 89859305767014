import { useContext, useEffect, useRef } from "react";
import Header from "../layout/Header";
import { SymfoniContext } from "../hardhat/SymfoniContext";

import { chain } from "../hooks/chains";
import Footer from "../pages/Footer";

const _getChainID = (resolve) => {
  if (window.ethereum.chainId != null) {
    resolve(window.ethereum.chainId);
  } else {
    setTimeout(() => _getChainID(resolve), 100);
  }
};
const getChainID = () => new Promise(_getChainID);

function BaseLayout({ children }) {
  const CHAINID = chain.chainId;

  const symphony = useContext(SymfoniContext);
  const init = useRef();

  useEffect(() => {
    if (window.ethereum && !init.current) {
      setTimeout(async () => {
        const chainId = await getChainID();
        if (chainId === CHAINID.toString()) {
          init.current = true;
          symphony.init("web3modal");
        } else {
          init.current = true;
          symphony.init("hardhat");
        }
      }, 250);
    } else if (!init.current) {
      init.current = true;
      symphony.init("hardhat");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex overflow-x-hidden h-full	absolute text-white  flex-col items-center w-full">
      <div
        style={{ width: "170%", height: "540px" }}
        className="bg-gradient-to-b z-30 opacity-60 overflow-hidden absolute rounded-b-lg md:rounded-b-fulll  from-dark via-dark to-gradientTwo"
      ></div>
      {/* <div style={{maxHeight: 120,
        backgroundImage: `url(${Gradient})`,

      }} className="absolute h-full top-0 z-40 w-full" /> */}

      <Header />
      <main
        style={{ minHeight: "100%" }}
        className="text-white flex flex-col  w-full"
      >
        {children}
      </main>
      <Footer />
    </div>
  );
}

export default BaseLayout;
