import { useState } from "react";
import Filter from "./Filter";
import HorizontalSelector from "./HorizontalSelector";

export default function CreatorFilter({ onApply, defaultOpen = null }) {
  const [payTokenAddress, setPayTokenAddress] = useState("");

  const data = [
    {
      value: false,
      label: (
        <div className="flex flex-center justify-center gap-1">
          <span className="text-center">Featured</span>
        </div>
      ),
    },
    {
      value: true,
      label: (
        <div className="flex flex-center justify-center gap-1">
          <span className="text-center">All</span>
        </div>
      ),
    },
  ];

  return (
    <Filter label="Creator" defaultOpen={defaultOpen}>
      <div className="flex gap-5 py-3 pb-5 px-1 flex-col">
        <HorizontalSelector
          selectedValue={payTokenAddress}
          onValueChanged={(e) => {
            let filter = e ? null : { verified: true };
            setPayTokenAddress(e);
            onApply(filter);
          }}
          options={data}
        />
      </div>
    </Filter>
  );
}
