import { Pool } from "../graphql/api";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Mint from "../components/Mint";
import moment from "moment";
import Footer from "./Footer";
import { getCurrency, getPoolId, usePoolDisplayData } from "../helpers/pools";
import RafflePieChart from "./Detail/PieChart";
import SetSeed from "./SetSeed";
import ResolveRaffle from "./ResolveRaffle";
import Resulted from "./Resulted";
import { formatEther } from "../helpers/payTokens";
import { isSameAddress } from "../helpers/address";
import { useEVM } from "../hooks/EVMhook";
import { dismissToast, loadingToast, successToast } from "../components/Toasts";
import { handleError } from "../helpers/errors";
import Address from "../components/Address";
import Countdown from "react-countdown";
import Ticket from "../assets/components/WhiteTicket.svg";
import cs from "classnames";

let ranks = require("../constants/ape_ranks.json");

const EXPLORER_URL = "https://evm.evmos.org/token/";

function Detail({ type }) {
  const { poolId } = useParams();

  const [loadingPool, setLoadingPools] = useState(false);
  const [data, setData] = useState(null);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const { info: poolInfo } = usePoolDisplayData(data);
  const { address, getController } = useEVM();
  const time = parseInt(new Date().getTime() / 1000);

  async function handleCancel() {
    const mintService = await getController(data);
    let toastId;
    try {
      const transaction = await mintService?.instance?.cancelPool(
        getPoolId(data)
      );
      setLoadingCancel(true);
      toastId = loadingToast(`Cancelling raffle...`);
      mintService.instance.provider
        .waitForTransaction(transaction.hash)
        .then(async () => {
          dismissToast(toastId);
          setLoadingCancel(false);
          successToast("Raffle cancelled!", {
            duration: 4000,
          });
        });
    } catch (error) {
      console.log(error);
      handleError(error);
      setLoadingCancel(false);
    }
  }

  function renderBalance() {
    const balance = data?.balances?.find((b) =>
      isSameAddress(b?.owner, address)
    );
    if (!balance) return null;

    return (
      <div
        className={cs(
          "flex absolute gap-1 z-30 top-2 px-1.5 items-center text-sm rounded-md border border-purple justify-center bg-purple",
          {
            "right-4": data?.type === "NFT",
            "right-0": data?.type === "TOKEN",
          }
        )}
      >
        You own {balance.count || 0}{" "}
        <img src={Ticket} className="w-5 h-5" alt="ticket" />
      </div>
    );
  }

  function renderTimer({ days, hours, minutes, seconds, completed }) {
    return (
      <div
        style={{ maxWidth: 350 }}
        className="flex h-9 gap-7 w-full self-center absolute z-40 bottom-0 items-center text-sm rounded-md font-bold justify-center bg-purple"
      >
        {completed && (
          <span className="uppercase">
            Completed on:{" "}
            {moment(new Date(data?.end * 1000).toISOString()).format(
              "MMM Do YYYY, h:mm a"
            )}
          </span>
        )}
        {!completed && (
          <>
            <span className="uppercase">Ending Soon</span>

            <div className="flex gap-2 -mt-2">
              <div className="flex items-center flex-col">
                <div className="flex gap-0.5">
                  <span className="text-semibold text-center text-lg w-5 px-1 shadow-lg bg-purpleShadow border border-purpleStroke rounded">
                    {0}
                  </span>
                  <span className="text-semibold text-center text-lg w-5 px-1 shadow-lg bg-purpleShadow border border-purpleStroke rounded">
                    {hours}
                  </span>
                </div>
                <span className="text-xs -mt-0.5 font-normal">Hours</span>
              </div>
              <div className="flex items-center flex-col">
                <div className="flex gap-0.5">
                  <span className="text-semibold text-lg text-center w-5 px-1 shadow-lg bg-purpleShadow border border-purpleStroke rounded">
                    {minutes > 10 ? minutes.toString()[0] : "0"}
                  </span>
                  <span className="text-semibold text-center text-lg w-5 px-1 shadow-lg bg-purpleShadow border border-purpleStroke rounded">
                    {minutes > 10 ? minutes.toString()[1] : minutes}
                  </span>
                </div>
                <span className="text-xs -mt-0.5 font-normal">Minutes</span>
              </div>
              <div className="flex items-center flex-col">
                <div className="flex gap-0.5">
                  <span className="text-semibold text-center text-lg w-5 px-1 shadow-lg bg-purpleShadow border border-purpleStroke rounded">
                    {seconds > 10 ? seconds.toString()[0] : "0"}
                  </span>
                  <span className="text-semibold text-center text-lg w-5 px-1 shadow-lg bg-purpleShadow border border-purpleStroke rounded">
                    {seconds > 10 ? seconds.toString()[1] : seconds}
                  </span>
                </div>
                <span className="text-xs -mt-0.5 font-normal">Seconds</span>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }

  function renderAction() {
    const time = parseInt(new Date().getTime() / 1000);
    if (data?.end > time && data?.max !== data?.totalSupply) {
      return (
        <div className="flex md:flex-row items-start flex-col gap-5">
          <Mint data={data} />
          {isSameAddress(data?.creator, address) && (
            <button
              className="outline-none h-10 self-start font-bold text-lg text-white hover:text-white bg-purple  w-40  px-2 rounded-full md:self-end"
              onClick={() => {
                handleCancel();
              }}
            >
              {loadingCancel ? "Loading.." : "Cancel Raffle"}
            </button>
          )}
        </div>
      );
    } else if (
      data?.end < time &&
      data?.totalSupply === "0" &&
      isSameAddress(data?.creator, address)
    ) {
      return (
        <button
          className="outline-none h-10 font-bold text-lg text-white hover:text-white bg-purple  w-40  px-2 rounded-full "
          onClick={() => {
            handleCancel();
          }}
        >
          {loadingCancel ? "Loading.." : "Cancel Raffle"}
        </button>
      );
    } else if (data?.resulted) {
      return <Resulted />;
    } else if (data?.seedSet) {
      return <ResolveRaffle data={data} />;
    } else if (!data?.seedSet) {
      return <SetSeed data={data} />;
    }
  }

  useEffect(() => {
    async function getData() {
      setLoadingPools(false);
      const id = type === "nft" ? "NFT:" + poolId : "TOKEN:" + poolId;
      Pool(id).then((response) => {
        setData(response);
        setLoadingPools(false);
      });
    }
    if (poolId) getData();
  }, [poolId, type]);
  return (
    <div className=" flex bg-dark justify-center " style={{}}>
      <div
        style={{
          minHeight: "100%",
        }}
        className="pt-14 z-40 items-center gap-10 flex-col flex w-full "
      >
        <div className="max-w-6xl md:px-0 px-6 md:bg-transparent mb-28 md:h-85 gap-8 md:flex-row flex-col flex w-full">
          {data && !loadingPool && (
            <>
              <div className="flex w-full gap-2 md:max-w-xs flex-col">
                {" "}
                {data?.creator_meta?.verified && (
                  <div className="flex w-full  gap-4 rounded-md p-2 bg-opacity-10 max-w-6xl">
                    <img
                      src={data?.creator_meta?.image}
                      alt="profile"
                      className="rounded-full border border-purple w-10 h-10"
                    />
                    <div className="flex  flex-col">
                      <span className="text-sm">Featured Creator</span>
                      <span className="-mt-1 font-semibold">
                        {data?.creator_meta?.name}
                      </span>
                    </div>
                  </div>
                )}
                <div className="relative justify-center h-80 flex">
                  {data?.balances?.length > 0 && renderBalance()}

                  <img
                    style={{ maxHeight: 300 }}
                    className="rounded-t w-full  md:max-w-xs object-contain"
                    alt="raffle preview"
                    src={poolInfo?.image}
                  />
                  {parseInt(data?.end) - time < 32400 && (
                    <Countdown
                      renderer={renderTimer}
                      date={new Date(data?.end * 1000)}
                    />
                  )}
                </div>
              </div>

              <div className="flex md:max-w-2xl pb-10 px-10 md:px-0 md:pb-0 bg-gradientTwo md:bg-transparent mt-0 w-full gap-16 flex-col">
                <div className="flex flex-col">
                  <span className="text-white font-medium border border-purple bg-purple bg-opacity-10 text-center rounded-lg py-1 mb-3 ">
                    Max draw date:{" "}
                    {data?.end
                      ? moment(new Date(data?.end * 1000).toISOString()).format(
                          "MMM Do YYYY"
                        )
                      : ""}{" "}
                    or when campaign is sold out. Whichever comes first
                  </span>
                  <div className="flex mb-3 mt-3 gap-3 justify-between items-center">
                    <div className="text-3xl gap-2 items-center flex font-bold mt-0 text-white">
                      {poolInfo?.name}{" "}
                      {isSameAddress(
                        data?.nft,
                        "0x4c275ade386af17276834579b1a68146cef3c770"
                      ) && (
                        <div className="flex border border-purple h-7 text-base text-white rounded  px-1.5">
                          rank: {ranks[data?.tokenId]}
                        </div>
                      )}
                    </div>
                  </div>
                  {isSameAddress(
                    data?.nft,
                    "0xcce4071459c70465428e1f03d0f381b4f4ea04af"
                  ) && (
                    <span>
                      The first ever Custom Orbital Ape by Baz! <br />
                      Work together with our Master Craftsman BAZ to create an
                      Ape tailored to you! <br />
                      Mint a ticket to leave your mark forever in the Orbital
                      Apes. <br />
                      <br />
                      *This ape will be minted in the Honorary Apes Collection
                    </span>
                  )}
                  {!isSameAddress(
                    data?.nft,
                    "0xcce4071459c70465428e1f03d0f381b4f4ea04af"
                  ) && (
                    <>
                      <span className="">
                        Mint an NFT Raffle Ticket to enter this draw.
                        <br /> Each Raffle Ticket costs{" "}
                        {data
                          ? formatEther(data?.price, data?.paytoken)
                          : null}{" "}
                        {getCurrency(data)} and the Raffle has a total supply of{" "}
                        {data?.max} Tickets.
                      </span>
                      <span className="text-white">{data?.description}</span>
                    </>
                  )}
                </div>

                {renderAction()}
              </div>
            </>
          )}
        </div>

        {isSameAddress(
          data?.nft,
          "0xcce4071459c70465428e1f03d0f381b4f4ea04af"
        ) && (
          <div className="flex pb-24 px-6  items-start gap-8 max-w-6xl self-center w-full flex-col">
            <span className="font-bold text-center  uppercase text-purple self-start text-4xl">
              Custom Honorary Apes
            </span>
            <span>
              Custom honorary apes are special 1:1 NFTs designed by Master
              Craftsman BAZ. The winner of this NFT will have a 1:1 session with
              BAZ and decide every attribute, color and accesory of their HOA!
            </span>
            <div className="flex gap-6 flex-col">
              <span className="font-bold text-center uppercase text-purple self-start text-xl">
                Custom honorary ape exmaples
              </span>
              <div className="flex gap-10 flex-wrap">
                <div className="flex items-center flex-col">
                  <img
                    className="w-32 h-32 rounded"
                    alt="hoa"
                    src="https://cloudflare-ipfs.com/ipfs/QmQsHFAhCGWcmEhQQk7GxEuyB9p83zAXqcdrKSdRULjtAK"
                  />
                  <span className="text-lg">Wosmongton</span>
                </div>
                <div className="flex items-center flex-col">
                  <img
                    className="w-32 h-32 rounded"
                    alt="hoa"
                    src="https://cloudflare-ipfs.com/ipfs/QmTxGYxpGkTexesvFVqpsjFkZsbwuHVateHkjaFBZXoiys"
                  />
                  <span className="text-lg">CosmosHOSS</span>
                </div>
                <div className="flex items-center flex-col">
                  <img
                    className="w-32 h-32 rounded"
                    alt="hoa"
                    src="https://cloudflare-ipfs.com/ipfs/QmPjXYF4bcebf1F3N78Esk6s2VxekQF4V9ikhaA68Hpysm"
                  />
                  <span className="text-lg">Sunny</span>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="flex pb-24 px-6  items-start gap-8 max-w-6xl self-center w-full flex-col">
          <span className="font-bold text-center  uppercase text-purple self-start text-4xl">
            raffle details
          </span>
          <div className="flex flex-col gap-1 border w-full border-darkGray p-4 rounded-md">
            <div className="flex gap-3">
              <span className="text-purple w-32 font-medium">Name:</span>
              <span>{poolInfo?.name}</span>
            </div>
            <div className="flex gap-3">
              <span className="text-purple w-32 font-medium">Created at:</span>
              <span>
                {" "}
                {data
                  ? moment(new Date(data?.start * 1000).toISOString()).format(
                      "MMM Do YYYY, h:mm a"
                    )
                  : null}
              </span>
            </div>
            <div className="flex gap-3">
              <span className="text-purple w-32 font-medium">Ends:</span>
              <span>
                {" "}
                {data
                  ? moment(new Date(data?.end * 1000).toISOString()).format(
                      "MMM Do YYYY, h:mm a"
                    )
                  : null}
              </span>
            </div>
            <div className="flex gap-3">
              <span className="text-purple w-32 font-medium">Created by:</span>
              <Address address={data?.creator} ellipsis={true} length={6} />
            </div>
            <div className="flex gap-3">
              <span className="text-purple w-32 font-medium">Type:</span>
              <span>{data?.type}</span>
            </div>
            <div className="flex gap-3">
              <span className="text-purple w-32 font-medium">Contract:</span>
              <a
                target="_blank"
                className="text-purple text-center"
                href={`${EXPLORER_URL}${data?.poolAddress}`}
                rel="noreferrer"
              >
                <Address
                  ellipsis={true}
                  length={6}
                  address={data?.poolAddress}
                />
              </a>
            </div>
            <div className="flex gap-3">
              <span className="text-purple w-32 font-medium">
                Tickets Sold:
              </span>
              <span>{data?.totalSupply}</span>
            </div>
            <div className="flex gap-3">
              <span className="text-purple w-32 font-medium">Supply:</span>
              <span>{data?.max}</span>
            </div>
            <div className="flex gap-3">
              <span className="text-purple w-32 font-medium">
                Price per ticket:
              </span>
              <span>
                {data ? formatEther(data?.price, data?.payToken) : null}{" "}
                {getCurrency(data)}
              </span>
            </div>
          </div>
        </div>

        {data?.winners?.length > 0 && (
          <div className="flex pb-24 px-6  items-start gap-8 max-w-6xl self-center w-full flex-col">
            <span className="font-bold text-center  uppercase text-purple self-start text-4xl">
              Winners
            </span>
            <div className="flex flex-wrap gap-5  w-full rounded-md">
              {data?.winners?.map((w, index) => (
                <div
                  key={index}
                  className="border gap-2 w-48 px-2 py-1.5 rounded flex flex-col border-purple bg-purple bg-opacity-10"
                >
                  <span className="font-semibold text-purple">
                    Ticket #{w?.winnerTokenId}
                  </span>
                  <div className="flex flex-col">
                    <span className="text-white">Owner</span>

                    <span className="text-gray   text-sm">
                      <Address
                        address={w?.winnerAddress}
                        ellipsis={true}
                        length={6}
                      />
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="flex pb-44 px-6  items-start gap-8 max-w-6xl self-center w-full flex-col">
          <span className="font-bold text-center  uppercase text-purple self-start text-4xl">
            top players
          </span>

          <div className="flex  rounded-md  w-full  flex-col">
            <div className="flex gap-1 ">
              <RafflePieChart
                winners={data?.winners}
                data={data?.balances || []}
              />
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}

export default Detail;
