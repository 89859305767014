import { useState } from "react";
import Filter from "./Filter";
import HorizontalSelector from "./HorizontalSelector";
import { payTokenMeta } from "../../helpers/payTokens";
import { isSameAddress } from "../../helpers/address";

export default function PayTokenFilter({
  onApply,
  defaultOpen = null,
  currencies,
}) {
  const [payTokenAddress, setPayTokenAddress] = useState("");

  const data = currencies?.map((c) => {
    const info = payTokenMeta[c.id];
    return {
      value: c.id,
      label: (
        <div className="flex flex-center justify-center gap-1">
          <span className="text-center">{info?.name}</span>
        </div>
      ),
    };
  });

  return (
    <Filter label="Ticket token" defaultOpen={defaultOpen}>
      <div className="flex gap-5 py-3 pb-5 px-1 flex-col">
        <HorizontalSelector
          selectedValue={payTokenAddress}
          onValueChanged={(e) => {
            if (isSameAddress(e, payTokenAddress)) {
              setPayTokenAddress("");
            } else {
              setPayTokenAddress(e);
            }
            onApply(e);
          }}
          options={data}
        />
      </div>
    </Filter>
  );
}
