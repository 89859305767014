import { useState } from "react";
import MobileHeader from "./MobileHeader";
import DesktopHeader from "./DesktopHeader";

function Header() {
  const [show, setShow] = useState(false);
  return (
    <>
      {show && <MobileHeader setShow={setShow} />}
      <DesktopHeader setShow={setShow} />
    </>
  );
}

export default Header;
