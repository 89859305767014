import { useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import BuyModal from "./BuyModal";
import moment from "moment";
import { getCurrency, usePoolDisplayData, getPoolURL } from "../helpers/pools";
import cs from "classnames";
import { formatEther } from "../helpers/payTokens";
import verified from "../assets/icons/verified.svg";
import { isSameAddress } from "../helpers/address";
import Ticket from "../assets/components/WhiteTicket.svg";
import Countdown from "react-countdown";

let ranks = require("../constants/ape_ranks.json");

function Raffle({ data, ownedCount }) {
  const [showModal, setShowModal] = useState(false);
  const { info: poolInfo } = usePoolDisplayData(data);
  const time = parseInt(new Date().getTime() / 1000);
  function renderBalance() {
    const balance = data?.balances[0];

    return (
      <div className="flex absolute right-28 gap-1 z-30 top-11 px-1.5 items-center text-sm rounded-md border border-purple justify-center bg-purple">
        You own {balance.count || 0}{" "}
        <img src={Ticket} className="w-5 h-5" alt="ticket" />
      </div>
    );
  }

  function renderTimer({ days, hours, minutes, seconds, completed }) {
    return (
      <div className="flex h-9 gap-7 w-72 absolute mt-60 z-40 items-center text-sm rounded-md font-bold justify-center bg-purple">
        {!completed && (
          <>
            <span className="uppercase">Ending Soon</span>

            <div className="flex gap-2 -mt-2">
              <div className="flex items-center flex-col">
                <div className="flex gap-0.5">
                  <span className="text-semibold text-center text-lg w-5 px-1 shadow-lg bg-purpleShadow border border-purpleStroke rounded">
                    {0}
                  </span>
                  <span className="text-semibold text-center text-lg w-5 px-1 shadow-lg bg-purpleShadow border border-purpleStroke rounded">
                    {hours}
                  </span>
                </div>
                <span className="text-xs -mt-0.5 font-normal">Hours</span>
              </div>
              <div className="flex items-center flex-col">
                <div className="flex gap-0.5">
                  <span className="text-semibold text-lg text-center w-5 px-1 shadow-lg bg-purpleShadow border border-purpleStroke rounded">
                    {minutes > 10 ? minutes.toString()[0] : "0"}
                  </span>
                  <span className="text-semibold text-center text-lg w-5 px-1 shadow-lg bg-purpleShadow border border-purpleStroke rounded">
                    {minutes > 10 ? minutes.toString()[1] : minutes}
                  </span>
                </div>
                <span className="text-xs -mt-0.5 font-normal">Minutes</span>
              </div>
              <div className="flex items-center flex-col">
                <div className="flex gap-0.5">
                  <span className="text-semibold text-center text-lg w-5 px-1 shadow-lg bg-purpleShadow border border-purpleStroke rounded">
                    {seconds > 10 ? seconds.toString()[0] : "0"}
                  </span>
                  <span className="text-semibold text-center text-lg w-5 px-1 shadow-lg bg-purpleShadow border border-purpleStroke rounded">
                    {seconds > 10 ? seconds.toString()[1] : seconds}
                  </span>
                </div>
                <span className="text-xs -mt-0.5 font-normal">Seconds</span>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }

  function handleCarouselContainerClicked(event) {
    if (event.target.id === "token-container") {
      // We clicked the carousel but not the image
      event.preventDefault(); // Stop the href from taking us to the bundle NFTs page
      event.stopPropagation(); // Don't fire other events
    }
  }

  return (
    <a
      href={getPoolURL(data)}
      onClick={handleCarouselContainerClicked}
      style={{ width: 460 }}
      className="flex relative hover:shadow-3xl p-2 text-white items-center z-0 rounded-lg flex-col"
    >
      {showModal && (
        <BuyModal
          closeModal={() => setShowModal(false)}
          data={{ ...data, ...poolInfo }}
        />
      )}
      <div className="rounded-t h-64 flex justify-center items-center  border-medium pt-4">
        <img
          className={cs("rounded-t -mb-8 z-20 h-60 w-60 object-contain", {})}
          alt="raffle preview"
          src={poolInfo?.image}
        />

        {isSameAddress(
          data?.nft,
          "0x4c275ade386af17276834579b1a68146cef3c770"
        ) && (
          <div className="flex border border-purple text-white rounded bg-purple bg-opacity-50 px-1.5 text-sm left-28 absolute z-30 top-11">
            rank: {ranks[data?.tokenId]}
          </div>
        )}

        {data?.balances?.length > 0 && renderBalance()}
      </div>
      {parseInt(data?.end) - time < 32400 && (
        <Countdown renderer={renderTimer} date={new Date(data?.end * 1000)} />
      )}

      <div
        id="token-container"
        style={{ borderRadius: "20px" }}
        className={cs(
          "px-6 py-4 border-2 border-purple cursor-default  flex items-center justify-between w-full",
          {
            "bg-gradient-to-b from-gradientTwo to-gradientOne": data?.auto,
            "bg-purple bg-opacity-10": !data?.auto,
          }
        )}
      >
        <div
          id="token-container"
          className="flex w-56   justify-center flex-col"
        >
          <div className="flex items-center gap-1">
            <span
              id="token-container"
              className="text-2xl   font-bold  text-purple"
            >
              WIN
            </span>
            {data?.creator_meta?.verified && (
              <div className="">
                <img src={verified} className="w-6 h-6 mt-0.5" alt="nadge" />
              </div>
            )}
          </div>

          <span
            id="token-container"
            className="font-bold   uppercase w-full -mt-1.5   text-white"
          >
            {poolInfo.name}
          </span>
        </div>
        <div
          id="token-container"
          className={cs("border-r flex    h-8 border-purple ", {})}
        />
        <div id="token-container" className="flex   flex-col">
          <div
            id="token-container"
            className="flex   text-white w-12 px-4 items-center leading-5 pt-1 font-bold rounded-bl-lg  justify-center h-full text-center"
          >
            {formatEther(data?.price?.toString(), data?.payToken)}{" "}
            {getCurrency(data)}
          </div>
        </div>

        <div
          id="token-container"
          className="border-r   flex  h-8 border-purple"
        />

        <button
          id="token-container"
          onClick={() => setShowModal(true)}
          className="flex text-purple    items-center w-16 pt-1 font-bold rounded-br-lg  justify-center text-center"
        >
          MINT TICKET
        </button>
      </div>
      <div
        id="token-container"
        className="flex cursor-default self-start w-full mt-2 justify-between  "
      >
        <span id="token-container" className="text-gray text-sm ">
          Drawn on sell out or after:{" "}
          {data?.end && (
            <span id="token-container" className="font-bold">
              {" "}
              {moment(new Date(data?.end * 1000).toISOString()).format(
                "MMM Do YYYY, h:mm a"
              )}
            </span>
          )}
        </span>
        <span id="token-container" className="text-white font-bold">
          {data?.totalSupply + "/" + data?.max} SOLD
        </span>
      </div>
    </a>
  );
}

export default Raffle;
