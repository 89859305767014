import Modal from "react-modal";
import { Line } from "rc-progress";
import { getPoolURL, getTitle } from "../helpers/pools";
import Mint from "./Mint";
import moment from "moment";
import { Link } from "react-router-dom";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    borderRadius: "0.5rem",
    padding: "0px",
    backgroundColor: "rgba(13, 15,16, 0.9)",
  },
  overlay: {
    backgroundColor: "rgba(7, 9,10, 0.8)",
  },
};

function NetworkModal({ closeModal, data }) {
  return (
    <div
      style={{
        filter: "blur(2px)",
        maxHeight: "95vh",
        overflow: "auto",
      }}
    >
      <Modal
        onRequestClose={() => closeModal()}
        isOpen={true}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="bg-dark rounded-lg  bg-opacity-5 border-2 border-purple p-4 flex md:flex-row flex-col  items-center justify-center gap-2">
          <>
            <div className="flex gap-3 max-w-xs w-full flex-col">
              <Link to={getPoolURL(data)}>
                <img
                  style={{ maxHeight: 190, minWidth: 160 }}
                  className="rounded-t w-full md:max-w-xs  object-contain"
                  alt="raffle preview"
                  src={data.image}
                />
              </Link>
              <div
                style={{ height: "18px" }}
                className="flex justify-center  items-center"
              >
                <span className="absolute font-bold text-sm mt-0.5 text-white">
                  {data?.totalSupply + "/" + data?.max + " SOLD"}
                </span>

                <Line
                  trailWidth="7"
                  trailColor="rgb(0,0,0,0)"
                  className="border border-purple rounded-full bg-purple bg-opacity-10"
                  percent={(data?.totalSupply / data?.max) * 100}
                  strokeWidth="7"
                  strokeColor="#8454EB"
                />
              </div>
            </div>
            <div className="flex pt-2  w-full justify-between md:h-85  pl-0 md:pl-4  flex-col">
              <div className="flex md:w-96 flex-col">
                <span className="text-gray border border-purple text-center rounded-lg py-1 mb-2 text-sm">
                  Max draw date:{" "}
                  {moment(new Date(data?.end * 1000).toISOString()).format(
                    "MMM Do YYYY"
                  )}{" "}
                  or when campaign is sold out.
                </span>
                <div className="flex  justify-between items-center">
                  <Link to={getPoolURL(data)}>
                    <span className="text-3xl font-bold mb-2 text-purple">
                      {getTitle(data)}
                    </span>
                  </Link>
                </div>
                <span className="text-white">{data.description}</span>
              </div>
              <Mint data={data} onClick />
            </div>
          </>
        </div>
      </Modal>
    </div>
  );
}

export default NetworkModal;
