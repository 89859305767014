import { useState } from "react";
import { handleError } from "../helpers/errors";
import { useEVM } from "./EVMhook";
import { loadingToast, dismissToast, successToast } from "../components/Toasts";
import {
  DEFAULT_ERC20,
  formatEther,
  parseEther,
  ZERO_ADDRESS,
} from "../helpers/payTokens";
import { isSameAddress } from "../helpers/address";

export function useApproveERC20(onTransactionSuccess) {
  const [loading, setLoading] = useState(false);
  const { erc20Service, tokenRaffleController } = useEVM();

  async function approve(tokenAddress = DEFAULT_ERC20, controller) {
    if (isSameAddress(tokenAddress, ZERO_ADDRESS)) {
      return onTransactionSuccess(2);
    }
    setLoading(true);

    try {
      const _controller = controller
        ? controller
        : tokenRaffleController?.instance?.address;

      const transaction = await erc20Service.instance
        .attach(tokenAddress)
        .approve(_controller, parseEther("1000000", tokenAddress));
      const toastId = loadingToast("Approving wevmos...");
      erc20Service?.instance
        ?.attach(tokenAddress)
        ?.provider?.waitForTransaction(transaction.hash)
        .then(() => {
          setLoading(false);
          dismissToast(toastId);
          successToast("WEVMOS approved!", {
            duration: 4000,
          });
          onTransactionSuccess(2);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      handleError(error);
    }
  }

  async function checkApproval(
    address,
    tokenAddress = DEFAULT_ERC20,
    controller
  ) {
    if (isSameAddress(tokenAddress, ZERO_ADDRESS)) {
      return onTransactionSuccess(2);
    }
    try {
      const _controller = controller
        ? controller
        : tokenRaffleController?.instance?.address;
      const _approvedAmount = await erc20Service.instance
        .attach(tokenAddress)
        .allowance(address, _controller);
      if (!_approvedAmount) {
        return onTransactionSuccess(1);
      }
      const approvedAmount = parseFloat(
        formatEther(_approvedAmount, tokenAddress)
      );
      if (approvedAmount <= 1000000 / 2) {
        onTransactionSuccess(1);
      } else {
        onTransactionSuccess(2);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  return {
    approve,
    checkApproval,
    loading,
  };
}

export function useApproveERC721(data, onTransactionSuccess) {
  const [loading, setLoading] = useState(false);

  const { nftRaffleController, erc721Contract, erc1155Contract } = useEVM();

  const ercNFTContract = data?.isERC1155 ? erc1155Contract : erc721Contract;

  async function approve(address) {
    const _nftAddress = address
      ? address
      : nftRaffleController?.instance?.address;
    setLoading(true);

    try {
      const transaction = await ercNFTContract?.instance
        ?.attach(data?.nftAddress)
        ?.setApprovalForAll(_nftAddress, true);
      const toastId = loadingToast("Approving NFTs...");
      ercNFTContract?.instance
        ?.attach(data?.nftAddress)
        .provider?.waitForTransaction(transaction.hash)
        .then(() => {
          setLoading(false);
          dismissToast(toastId);
          successToast("NFTs approved!");
          onTransactionSuccess(2);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      handleError(error);
    }
  }

  async function checkApproval(userAddress, address) {
    setLoading(true);

    const _nftAddress = address
      ? address
      : nftRaffleController?.instance?.address;

    const ercNFTContract = data?.isERC1155 ? erc1155Contract : erc721Contract;

    try {
      const isApproved = await ercNFTContract?.instance
        ?.attach(data?.nftAddress)
        ?.isApprovedForAll(userAddress, _nftAddress);
      setLoading(false);
      if (!isApproved) {
        onTransactionSuccess(1);
      } else {
        onTransactionSuccess(2);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      handleError(error);
    }
  }

  return {
    checkApproval,
    approve,
    loading,
  };
}
