import { useEffect, useState } from "react";
import Modal from "react-modal";
import { MyTokens } from "../graphql/api";
import { getPoolId } from "../helpers/pools";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0px",
    borderRadius: "0.5rem",
    padding: "0px",
    backgroundColor: "rgba(13, 15,16, 0.9)",
  },
  overlay: {
    backgroundColor: "rgba(7, 9,10, 0.8)",
  },
};

function NetworkModal({ closeModal, data, address }) {
  const [tickets, setTickets] = useState([]);

  useEffect(() => {
    if (address && data.id) {
      MyTokens(address, getPoolId(data)).then((tokens) => {
        setTickets(tokens);
      });
    }
  }, [data, address]);

  return (
    <div
      style={{
        filter: "blur(2px)",
        maxHeight: "95vh",
        overflow: "auto",
      }}
    >
      <Modal
        onRequestClose={() => closeModal()}
        isOpen={true}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="bg-dark p-6 max-w-md flex flex-col md:items-start md:justify-start items-center justify-center border-2 border-purple gap-2 rounded-lg bg-opacity-5">
          <span className="uppercase w-72 text-white font-bold">
            your <span className="text-purple">{data.name}</span> raffle entries
          </span>
          <div className="border-t w-full h-0.5 flex flex-col border-white" />
          <div
            style={{ maxHeight: 250 }}
            className="flex w-full gap-2 overflow-y-scroll flex-wrap"
          >
            {tickets && tickets.length > 0
              ? tickets.map((i, index) => (
                  <span
                    className="border px-3 py-1 border-purple text-sm  text-white rounded"
                    key={index}
                  >
                    Ticket ID. {i.tokenId}
                  </span>
                ))
              : null}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default NetworkModal;
