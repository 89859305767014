import {
  Symfoni as MainSymfoni,
  SymfoniContext as MainSymfoniContext,
} from "../hardhat/SymfoniContext";

import {
  Symfoni as TestSymfoni,
  SymfoniContext as TestSymfoniContext,
} from "../hardhat/SymfoniContext";

import {
  Symfoni as MumbaiSymfoni,
  SymfoniContext as MumbaiSymfoniContext,
} from "../hardhat/SymfoniContext";

export const isTEvmos = process.env.REACT_APP_ENV === "tevmos";
export const isProd = true;

export const Symfoni = isProd
  ? MainSymfoni
  : isTEvmos
  ? TestSymfoni
  : MumbaiSymfoni;
export const SymfoniContext = isProd
  ? MainSymfoniContext
  : isTEvmos
  ? TestSymfoniContext
  : MumbaiSymfoniContext;
