import { useHistory } from "react-router-dom";
import cs from "classnames";
import Logo from "../assets/cards.png";
import Hamburger from "../components/Hamburger";
import { useEVM } from "../hooks/EVMhook";
import rumble from "../assets/Rumble.png";

function MobileHeader({ setShow }) {
  const { address, isConnected, isNetwork, isMetamask, addNetwork } = useEVM();

  function getButton() {
    if (!isMetamask) return "You need Metamask";
    if (isConnected && isNetwork) return address?.substring(0, 9);
    else if (isNetwork && !isConnected) return "Connect Wallet";
    else return "Switch to EVMOS";
  }

  const history = useHistory();
  return (
    <header
      className={cs(`px-5 md:px-10 z-30 bg-dark py-4 w-full items-center flex`)}
    >
      <nav className="font-semibold max-w-6xl text-white flex items-center container w-full justify-between mx-auto">
        <div className="flex items-center gap-2">
          <img
            onClick={() => {
              history.push("/");
              setShow(false);
            }}
            alt="logo"
            className=" cursor-pointer h-10"
            src={Logo}
          />
          <img
            onClick={() => {
              history.push("/");
              setShow(false);
            }}
            alt="logo"
            className=" cursor-pointer h-10"
            src={rumble}
          />
        </div>
        <Hamburger onClick={() => setShow(true)} />
        <div className="md:flex hidden gap-4 items-center">
          <span
            onClick={() => {
              history.push("/");
              setShow(false);
            }}
            className="text-white uppercase hover:text-network font-medium  cursor-pointer"
          >
            Home
          </span>
          <div className="bg-white h-4 w-0.5" />

          <span
            onClick={() => {
              history.push("/winners");
              setShow(false);
            }}
            className="text-white uppercase hover:text-network font-medium  cursor-pointer"
          >
            Past Raffles
          </span>
          <div className="bg-white h-4 w-0.5" />
          <span
            onClick={() => {
              history.push("/stats");
              setShow(false);
            }}
            className="text-white uppercase hover:text-network font-medium  cursor-pointer"
          >
            Stats
          </span>
          <div className="bg-white h-4 w-0.5" />
          <span
            onClick={() => {
              history.push("/profile");
              setShow(false);
            }}
            className="text-white uppercase hover:text-network font-medium  cursor-pointer"
          >
            Profile
          </span>

          <button
            onClick={() => {
              if (isMetamask && isConnected && isNetwork) {
                history.push("/profile");
                setShow(false);
              }
              if (!isMetamask) {
                window.open(
                  "https://evmos.dev/guides/keys-wallets/metamask.html",
                  "_blank"
                );
              }
              if (isMetamask && (!isConnected || !isNetwork)) {
                addNetwork();
              }
            }}
            className="border px-3 border-white bg-transparent outline-none rounded"
          >
            {getButton()}
          </button>
        </div>
      </nav>
    </header>
  );
}

export default MobileHeader;
