import cs from "classnames";
import { useTokenData } from "../helpers/tokens";

export default function NftTokenCard({ data, onClick, selected }) {
  const info = useTokenData(data?.tokenId, data?.nftAddress, false);
  return (
    <div
      onClick={() => onClick({ ...data, ...info })}
      className={cs(
        "flex p-2 pt-1 hover:border-purple cursor-pointer gap-1 w-36 border rounded items-center flex-col",
        {
          "border-purple": selected === data?.tokenId,
          "border-darkGray": selected !== data?.tokenId,
        }
      )}
    >
      <span className="font-medium overflow-hidden">{data?.name}</span>
      <img alt="preview" className="rounded w-14 h-14" src={info?.image} />
    </div>
  );
}
