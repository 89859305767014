import { useState } from "react";
import BuyModal from "./BuyModal";
import { Line } from "rc-progress";
import { getCurrency, getPoolURL, usePoolDisplayData } from "../helpers/pools";
import cs from "classnames";
import { Link } from "react-router-dom";
import verified from "../assets/icons/verified.svg";
import { formatEther } from "../helpers/payTokens";
import { isSameAddress } from "../helpers/address";
let ranks = require("../constants/ape_ranks.json");

function TopCards({ data }) {
  const [showModal, setShowModal] = useState(false);
  const { info: poolInfo } = usePoolDisplayData(data);

  return (
    <div className="flex relative shadow-md hover:shadow-3xl mt-rounded-lg bg-opacity-0 w-64 bg-purple text-white border-t border-dark rounded flex-col">
      {showModal && (
        <BuyModal
          closeModal={() => setShowModal(false)}
          data={{ ...data, ...poolInfo }}
        />
      )}
      <div className="p-2 rounded-t gap-6 flex flex-col w-full ">
        <div
          style={{ height: "18px" }}
          className="flex justify-center items-center"
        >
          <span className="absolute font-bold text-sm mt-0.5 text-white">
            {data?.totalSupply + "/" + data?.max + " SOLD"}
          </span>

          <Line
            trailColor="rgb(0,0,0,0)"
            className="border border-purple rounded-full bg-purple bg-opacity-10"
            percent={(data?.totalSupply / data?.max) * 100}
            strokeWidth="9"
            strokeColor="#8454EB"
          />
        </div>
        <Link className="self-center z-20" to={getPoolURL(data)}>
          {data?.creator_meta?.verified && (
            <div className="flex right-10 absolute z-30 top-12">
              <img src={verified} className="w-7 h-7 mt-0.5" alt="nadge" />
            </div>
          )}
          <img
            className="self-center -mb-4  w-44 h-44 rounded-t-md object-contain"
            alt="raffle preview"
            src={poolInfo?.image}
          />
        </Link>
      </div>
      <div
        onClick={() => setShowModal(true)}
        className={cs(
          "flex border border-purple cursor-pointer relative rounded-lg flex-col",
          {
            "bg-gradient-to-b from-gradientTwo to-gradientOne": data.auto,
            "bg-purple bg-opacity-10": !data.auto,
          }
        )}
      >
        <span className="text-3xl font-bold text-center mt-5 text-purple">
          WIN
        </span>
        {isSameAddress(
          data?.nft,
          "0x4c275ade386af17276834579b1a68146cef3c770"
        ) && (
          <div className="flex border border-purple text-white rounded bg-opacityPurple bg-opacity-80 px-1.5 text-sm left-10 absolute z-40 -top-4">
            rank: {ranks[data?.tokenId]}
          </div>
        )}
        <div className="flex  items-center -mt-0.5 px-4 justify-between">
          <span className="font-bold uppercase w-full  text-center text-white">
            {poolInfo.name}
          </span>
        </div>
        <div
          style={{ height: "0.5px", backgroundColor: "rgb(117, 76, 203)" }}
          className=" mt-3 w-full"
        />
        <div className="flex h-9  rounded-b-lg  items-center font-semibold  ">
          <div className="w-full rounded-b h-full items-center  justify-between flex ">
            <div className="flex items-center w-44 pt-1 font-bold rounded-bl-lg  justify-center h-full text-white  text-center">
              {formatEther(data?.price, data?.paytoken)} {getCurrency(data)}
            </div>
            <div
              style={{ width: "2px", backgroundColor: "rgb(117, 76, 203)" }}
              className=" self-center  h-4"
            />

            <button className="flex items-center pt-1 font-semibold rounded-br-lg w-full justify-center h-full text-purple  text-center">
              MINT TICKET
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopCards;
