import { useState } from "react";
import { useEVM } from "../hooks/EVMhook";

import TicketsModal from "../components/TicketsModal";
import { getCurrency, getPoolURL, usePoolDisplayData } from "../helpers/pools";
import { Link } from "react-router-dom";
import { formatEther, getRaffleTitle } from "../helpers/payTokens";

const EXPLORER_URL = "https://evm.evmos.org/token/";

function TicketCard({ data, metadata }) {
  const { address } = useEVM();
  const [showModal, setShowModal] = useState(false);
  const { info: poolInfo } = usePoolDisplayData(data);

  return (
    <div className="ticket flex gap-3 rounded-t-lg pt-5 bg-darkGray shadow-md hover:shadow-3xl w-80  text-white   rounded-lg flex-col">
      {showModal && (
        <TicketsModal
          address={address}
          data={{ ...data }}
          closeModal={() => setShowModal(false)}
        />
      )}
      <span className="w-full text-white  flex flex-col font-bold text-lg text-center uppercase ">
        {getRaffleTitle(data)}
      </span>
      <div className="flex justify-between w-full  bg-dark rounded-b-lg rounded-t-lg py-2 pb-5 flex-col">
        <span className="text-white font-semibold text-center pt-3">
          {poolInfo?.name} RAFFLE{" "}
        </span>
        <Link to={getPoolURL(data)} className="py-6 flex justify-center">
          <img
            style={{ height: 150, width: 150 }}
            className="rounded-t object-contain"
            alt="raffle preview"
            src={poolInfo?.image}
          />
        </Link>
        <div className="border-b mb-6 border-purple" />
        <div className="flex flex-col gap-4 justify-between">
          <div className="flex justify-around items-center ">
            <div className="flex items-center font-bold text-xl flex-col">
              <span className="text-white">Price</span>
              <span className="text-purple">
                {formatEther(data?.price, data?.payToken)} {getCurrency(data)}
              </span>
            </div>
            <div className="flex items-center font-bold text-xl flex-col">
              <span className="text-white">Supply</span>
              <span className="text-purple">{data?.max}</span>
            </div>
            <div className="flex items-center font-bold text-xl flex-col">
              <span className="text-white">Sold</span>
              <span className="text-purple">{data?.totalSupply}</span>
            </div>
          </div>
          <div className="border-r  border-purple" />
          <div className="flex  px-4 gap-5 justify-between items-center flex-col">
            <button
              onClick={() => setShowModal(true)}
              className=" w-full py-2 text-center  rounded-lg font-bold bg-purple text-lg "
            >
              YOUR ENTRIES ({data?.balances ? data.balances[0]?.count : 0})
            </button>
            <div className="flex self-start text-xs justify-start ">
              <div className="flex items-start flex-col">
                <span className="text-start text-base text-white">
                  Contract
                </span>
                <a
                  target="_blank"
                  className="text-wite font-bold text-center"
                  href={`${EXPLORER_URL}${data?.poolAddress}`}
                  rel="noreferrer"
                >
                  {`evmos.org/token/${data?.poolAddress?.substring(0, 8)}`}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TicketCard;
