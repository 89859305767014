import { isSameAddress } from "./address";
import { collectionMeta } from "./collectionInfo";
import { formatEther, payTokenMeta } from "./payTokens";
import { useTokenData } from "./tokens";

export function getCurrency(data) {
  const meta = payTokenMeta[data?.payToken];
  return meta?.name;
}

export function getTitle(pool) {
  if (pool.auto) {
    return `${
      pool.totalSupply * formatEther(pool?.price, pool?.payToken)
    } ${getCurrency(pool)}`;
  }
  return pool.name;
}

function useERC20Data(address, amount, isERC20, nothing) {
  const image = payTokenMeta[address]?.image;
  const name = `${formatEther(amount, address)} ${payTokenMeta[address]?.name}`;
  return { image, name };
}

function useNFTData(tokenId, address, isERC1155, isERC20) {
  const collectionInfo = collectionMeta[address];
  const info = useTokenData(tokenId, address, isERC1155);
  let _name = `${collectionInfo?.name} #${tokenId}`;
  if (isSameAddress(address, "0xabbaa322a763b36587e3f63e46a81deacb2957a7")) {
    _name = `${collectionInfo?.name}`;
  }
  if (isSameAddress(address, "0xcce4071459c70465428e1f03d0f381b4f4ea04af")) {
    _name = `Custom Ape by Baz`;
  }
  return { ...info, name: _name };
}

export function usePoolDisplayData(data) {
  const isERC20 = data?.type === "TOKEN";
  const a = isERC20 ? data?.prizeToken : data?.tokenId;
  const b = isERC20 ? data?.amount : data?.nft;
  const c = isERC20 ? null : false;

  const info_1 = useERC20Data(a, b, isERC20);
  const info_2 = useNFTData(a, b, c, isERC20);

  return { info: isERC20 ? info_1 : info_2 };
}

export function getPoolURL(data) {
  if (!data) return "";
  const id = data?.id?.split(":")[1];
  if (data.type === "NFT") {
    return `/pool/nft/${id}`;
  } else {
    return `/pool/token/${id}`;
  }
}

export function getPoolId(data) {
  if (!data) return "";
  return data?.id?.split(":")[1];
}
