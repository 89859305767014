import TopCards from "../../components/TopCards";
import Slider from "react-slick";
import arrow from "../../assets/components/arrow.svg";
import { useState, useEffect } from "react";
import { AutoPools } from "../../graphql/api";
import { useEVM } from "../../hooks/EVMhook";

const settings = {
  className: "center w-full",
  centerMode: true,
  infinite: true,
  centerPadding: "60px",
  arrows: false,
  slidesToShow: 3,
  speed: 500,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function TopPools() {
  const { address } = useEVM();

  const [loadingTop, setLoadingTop] = useState(true);
  const [topPools, setTopPools] = useState([]);
  const [slider, setSlider] = useState({});

  useEffect(() => {
    async function getData() {
      setLoadingTop(true);
      AutoPools().then((_topPools) => {
        setTopPools(_topPools);
        setLoadingTop(false);
      });
    }
    if (address) getData();
  }, [address]);

  function onChange(direction) {
    if (direction === 1) {
      slider.slickNext();
    } else if (direction === -1) {
      slider.slickPrev();
    }
  }
  return (
    <div>
      <div className="gap-11 max-w-6xl w-full flex justify-between flex-wrap">
        <div className="flex w-full justify-center flex-col">
          <span className="font-bold uppercase mt-16 text-purple w-full text-center self-start text-4xl">
            Auto Funded Campaigns
          </span>
          <span className="w-full text-2xl font-medium text-center text-white">
            The price is the total of all tickets purchased
          </span>
        </div>
      </div>
      {topPools?.length <= 0 && !loadingTop && (
        <div className="h-44 flex items-center">
          <span className="text-xl text-white">
            There are currently no Auto Funded Campaigns Open
          </span>
        </div>
      )}
      <>
        <div className="gap-7 mt-10 mb-10 max-w-6xl w-full flex ">
          {!loadingTop && topPools?.length > 0 && (
            <>
              {topPools?.length < 3 && (
                <div className="w-full flex flex-wrap gap-10 justify-center ">
                  {topPools.map((d, i) => {
                    return <TopCards data={d} key={i} />;
                  })}
                </div>
              )}
              {topPools?.length >= 3 && (
                <Slider ref={(c) => setSlider(c)} {...settings}>
                  {topPools.map((d, i) => {
                    return <TopCards key={i} data={d} />;
                  })}
                </Slider>
              )}
            </>
          )}
          {loadingTop && (
            <span className="text-white text-3xl text-center w-full animate-pulse font-semibold">
              Loading...
            </span>
          )}
        </div>
        {topPools?.length > 0 && topPools?.length >= 3 && (
          <div className="gap-6 pb-24 pt-10  h-8 items-center rounded flex ">
            <img
              onClick={() => onChange(-1)}
              className="transform rotate-180 w-5 cursor-pointer"
              alt="arrow"
              src={arrow}
            />
            <img
              onClick={() => onChange(1)}
              className="transform w-5 cursor-pointer"
              alt="arrow"
              src={arrow}
            />
          </div>
        )}
      </>
    </div>
  );
}

export default TopPools;
