const chains = {
  EvmosTest: {
    chainId: "0x2328",
    chainName: "Evmos Testnet",
    nativeCurrency: {
      name: "TEvmos",
      symbol: "TEVMOS",
      decimals: 18,
    },
    rpcUrls: ["https://eth.bd.evmos.dev:8545/"],
    blockExplorerUrls: ["https://evm.evmos.dev/"],
  },
  Evmos: {
    chainId: "0x2329",
    chainName: "Evmos",
    nativeCurrency: {
      name: "Evmos",
      symbol: "Evmos",
      decimals: 18,
    },
    rpcUrls: ["https://eth.bd.evmos.org:8545/"],
    blockExplorerUrls: ["https://evm.evmos.org/"],
  },
  PolygonTest: {
    chainId: "0x13881", // A 0x-prefixed hexadecimal chainId
    chainName: "Mumbai",
    nativeCurrency: {
      name: "Matic",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://rpc-mumbai.maticvigil.com/"],
    blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
  },
};

export const chain = chains.Evmos;
