/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { providers, Signer, ethers } from "ethers";
import React, { useEffect, useState } from "react";
import Web3Modal, { IProviderOptions } from "web3modal";
import { RafflePool } from "./typechain/RafflePool";
import { RafflePool__factory } from "./typechain/factories/RafflePool__factory";
import RaffleTokenRegistryDeployment from "./deployments/evmos/RaffleTokenRegistry.json";
import { RaffleTokenRegistry } from "./typechain/RaffleTokenRegistry";
import { RaffleTokenRegistry__factory } from "./typechain/factories/RaffleTokenRegistry__factory";
import VerifiedCreatorRegistryDeployment from "./deployments/evmos/VerifiedCreatorRegistry.json";
import { VerifiedCreatorRegistry } from "./typechain/VerifiedCreatorRegistry";
import { VerifiedCreatorRegistry__factory } from "./typechain/factories/VerifiedCreatorRegistry__factory";
import RumbleRewardsDistributorDeployment from "./deployments/evmos/RumbleRewardsDistributor.json";
import { RumbleRewardsDistributor } from "./typechain/RumbleRewardsDistributor";
import { RumbleRewardsDistributor__factory } from "./typechain/factories/RumbleRewardsDistributor__factory";
import NFTRaffleAddressRegistryDeployment from "./deployments/evmos/NFTRaffleAddressRegistry.json";
import { NFTRaffleAddressRegistry } from "./typechain/NFTRaffleAddressRegistry";
import { NFTRaffleAddressRegistry__factory } from "./typechain/factories/NFTRaffleAddressRegistry__factory";
import NFTRafflePoolControllerDeployment from "./deployments/evmos/NFTRafflePoolController.json";
import { NFTRafflePoolController } from "./typechain/NFTRafflePoolController";
import { NFTRafflePoolController__factory } from "./typechain/factories/NFTRafflePoolController__factory";
import TokenRaffleAddressRegistryDeployment from "./deployments/evmos/TokenRaffleAddressRegistry.json";
import { TokenRaffleAddressRegistry } from "./typechain/TokenRaffleAddressRegistry";
import { TokenRaffleAddressRegistry__factory } from "./typechain/factories/TokenRaffleAddressRegistry__factory";
import NFTRafflePoolFactoryDeployment from "./deployments/evmos/NFTRafflePoolFactory.json";
import { NFTRafflePoolFactory } from "./typechain/NFTRafflePoolFactory";
import { NFTRafflePoolFactory__factory } from "./typechain/factories/NFTRafflePoolFactory__factory";
import TokenRafflePoolControllerDeployment from "./deployments/evmos/TokenRafflePoolController.json";
import { TokenRafflePoolController } from "./typechain/TokenRafflePoolController";
import { TokenRafflePoolController__factory } from "./typechain/factories/TokenRafflePoolController__factory";
import TokenRafflePoolFactoryDeployment from "./deployments/evmos/TokenRafflePoolFactory.json";
import { TokenRafflePoolFactory } from "./typechain/TokenRafflePoolFactory";
import { TokenRafflePoolFactory__factory } from "./typechain/factories/TokenRafflePoolFactory__factory";
import { Currency } from "./typechain/Currency";
import { Currency__factory } from "./typechain/factories/Currency__factory";
import { DIARandomOracle } from "./typechain/DIARandomOracle";
import { DIARandomOracle__factory } from "./typechain/factories/DIARandomOracle__factory";
import { BoredApesTest } from "./typechain/BoredApesTest";
import { BoredApesTest__factory } from "./typechain/factories/BoredApesTest__factory";
import { WEVMOS } from "./typechain/WEVMOS";
import { WEVMOS__factory } from "./typechain/factories/WEVMOS__factory";
import { OrbitCollectionRegistryV2 } from "./typechain/OrbitCollectionRegistryV2";
import { OrbitCollectionRegistryV2__factory } from "./typechain/factories/OrbitCollectionRegistryV2__factory";
import { OrbitalApes } from "./typechain/OrbitalApes";
import { OrbitalApes__factory } from "./typechain/factories/OrbitalApes__factory";
import { ERC20 } from "./typechain/ERC20";
import { ERC20__factory } from "./typechain/factories/ERC20__factory";
import { ERC721 } from "./typechain/ERC721";
import { ERC721__factory } from "./typechain/factories/ERC721__factory";

const emptyContract = {
    instance: undefined,
    factory: undefined
};
const defaultProvider: providers.Provider | undefined = undefined;
export const ProviderContext = React.createContext<[providers.Provider | undefined, React.Dispatch<React.SetStateAction<providers.Provider | undefined>>]>([defaultProvider, () => { }]);
const defaultCurrentAddress: string = "";
export const CurrentAddressContext = React.createContext<[string, React.Dispatch<React.SetStateAction<string>>]>([defaultCurrentAddress, () => { }]);
const defaultSigner: Signer | undefined = undefined;
export const SignerContext = React.createContext<[Signer | undefined, React.Dispatch<React.SetStateAction<Signer | undefined>>]>([defaultSigner, () => { }]);
const defaultSymfoniContext: SymfoniContextInterface = {
    currentHardhatProvider: "",
    init: () => { throw Error("Symfoni context not initialized") },
    loading: false,
    messages: [],
    providers: []
};
export const SymfoniContext = React.createContext<SymfoniContextInterface>(defaultSymfoniContext);
export const RafflePoolContext = React.createContext<SymfoniRafflePool>(emptyContract);
export const RaffleTokenRegistryContext = React.createContext<SymfoniRaffleTokenRegistry>(emptyContract);
export const VerifiedCreatorRegistryContext = React.createContext<SymfoniVerifiedCreatorRegistry>(emptyContract);
export const RumbleRewardsDistributorContext = React.createContext<SymfoniRumbleRewardsDistributor>(emptyContract);
export const NFTRaffleAddressRegistryContext = React.createContext<SymfoniNFTRaffleAddressRegistry>(emptyContract);
export const NFTRafflePoolControllerContext = React.createContext<SymfoniNFTRafflePoolController>(emptyContract);
export const TokenRaffleAddressRegistryContext = React.createContext<SymfoniTokenRaffleAddressRegistry>(emptyContract);
export const NFTRafflePoolFactoryContext = React.createContext<SymfoniNFTRafflePoolFactory>(emptyContract);
export const TokenRafflePoolControllerContext = React.createContext<SymfoniTokenRafflePoolController>(emptyContract);
export const TokenRafflePoolFactoryContext = React.createContext<SymfoniTokenRafflePoolFactory>(emptyContract);
export const CurrencyContext = React.createContext<SymfoniCurrency>(emptyContract);
export const DIARandomOracleContext = React.createContext<SymfoniDIARandomOracle>(emptyContract);
export const BoredApesTestContext = React.createContext<SymfoniBoredApesTest>(emptyContract);
export const WEVMOSContext = React.createContext<SymfoniWEVMOS>(emptyContract);
export const OrbitCollectionRegistryV2Context = React.createContext<SymfoniOrbitCollectionRegistryV2>(emptyContract);
export const OrbitalApesContext = React.createContext<SymfoniOrbitalApes>(emptyContract);
export const ERC20Context = React.createContext<SymfoniERC20>(emptyContract);
export const ERC721Context = React.createContext<SymfoniERC721>(emptyContract);

export interface SymfoniContextInterface {
    init: (provider?: string) => void;
    loading: boolean;
    messages: string[];
    currentHardhatProvider: string;
    providers: string[];
}

export interface SymfoniProps {
    autoInit?: boolean;
    showLoading?: boolean;
    loadingComponent?: React.ReactNode;
}

export interface SymfoniRafflePool {
    instance?: RafflePool;
    factory?: RafflePool__factory;
}

export interface SymfoniRaffleTokenRegistry {
    instance?: RaffleTokenRegistry;
    factory?: RaffleTokenRegistry__factory;
}

export interface SymfoniVerifiedCreatorRegistry {
    instance?: VerifiedCreatorRegistry;
    factory?: VerifiedCreatorRegistry__factory;
}

export interface SymfoniRumbleRewardsDistributor {
    instance?: RumbleRewardsDistributor;
    factory?: RumbleRewardsDistributor__factory;
}

export interface SymfoniNFTRaffleAddressRegistry {
    instance?: NFTRaffleAddressRegistry;
    factory?: NFTRaffleAddressRegistry__factory;
}

export interface SymfoniNFTRafflePoolController {
    instance?: NFTRafflePoolController;
    factory?: NFTRafflePoolController__factory;
}

export interface SymfoniTokenRaffleAddressRegistry {
    instance?: TokenRaffleAddressRegistry;
    factory?: TokenRaffleAddressRegistry__factory;
}

export interface SymfoniNFTRafflePoolFactory {
    instance?: NFTRafflePoolFactory;
    factory?: NFTRafflePoolFactory__factory;
}

export interface SymfoniTokenRafflePoolController {
    instance?: TokenRafflePoolController;
    factory?: TokenRafflePoolController__factory;
}

export interface SymfoniTokenRafflePoolFactory {
    instance?: TokenRafflePoolFactory;
    factory?: TokenRafflePoolFactory__factory;
}

export interface SymfoniCurrency {
    instance?: Currency;
    factory?: Currency__factory;
}

export interface SymfoniDIARandomOracle {
    instance?: DIARandomOracle;
    factory?: DIARandomOracle__factory;
}

export interface SymfoniBoredApesTest {
    instance?: BoredApesTest;
    factory?: BoredApesTest__factory;
}

export interface SymfoniWEVMOS {
    instance?: WEVMOS;
    factory?: WEVMOS__factory;
}

export interface SymfoniOrbitCollectionRegistryV2 {
    instance?: OrbitCollectionRegistryV2;
    factory?: OrbitCollectionRegistryV2__factory;
}

export interface SymfoniOrbitalApes {
    instance?: OrbitalApes;
    factory?: OrbitalApes__factory;
}

export interface SymfoniERC20 {
    instance?: ERC20;
    factory?: ERC20__factory;
}

export interface SymfoniERC721 {
    instance?: ERC721;
    factory?: ERC721__factory;
}

export const Symfoni: React.FC<SymfoniProps> = ({
    showLoading = true,
    autoInit = true,
    ...props
}) => {
    const [initializeCounter, setInitializeCounter] = useState(0);
    const [currentHardhatProvider, setCurrentHardhatProvider] = useState("");
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState<string[]>([]);
    const [signer, setSigner] = useState<Signer | undefined>(defaultSigner);
    const [provider, setProvider] = useState<providers.Provider | undefined>(defaultProvider);
    const [currentAddress, setCurrentAddress] = useState<string>(defaultCurrentAddress);
    const [fallbackProvider] = useState<string | undefined>(undefined);
    const [providerPriority, setProviderPriority] = useState<string[]>(["web3modal", "hardhat"]);
    const [RafflePool, setRafflePool] = useState<SymfoniRafflePool>(emptyContract);
    const [RaffleTokenRegistry, setRaffleTokenRegistry] = useState<SymfoniRaffleTokenRegistry>(emptyContract);
    const [VerifiedCreatorRegistry, setVerifiedCreatorRegistry] = useState<SymfoniVerifiedCreatorRegistry>(emptyContract);
    const [RumbleRewardsDistributor, setRumbleRewardsDistributor] = useState<SymfoniRumbleRewardsDistributor>(emptyContract);
    const [NFTRaffleAddressRegistry, setNFTRaffleAddressRegistry] = useState<SymfoniNFTRaffleAddressRegistry>(emptyContract);
    const [NFTRafflePoolController, setNFTRafflePoolController] = useState<SymfoniNFTRafflePoolController>(emptyContract);
    const [TokenRaffleAddressRegistry, setTokenRaffleAddressRegistry] = useState<SymfoniTokenRaffleAddressRegistry>(emptyContract);
    const [NFTRafflePoolFactory, setNFTRafflePoolFactory] = useState<SymfoniNFTRafflePoolFactory>(emptyContract);
    const [TokenRafflePoolController, setTokenRafflePoolController] = useState<SymfoniTokenRafflePoolController>(emptyContract);
    const [TokenRafflePoolFactory, setTokenRafflePoolFactory] = useState<SymfoniTokenRafflePoolFactory>(emptyContract);
    const [Currency, setCurrency] = useState<SymfoniCurrency>(emptyContract);
    const [DIARandomOracle, setDIARandomOracle] = useState<SymfoniDIARandomOracle>(emptyContract);
    const [BoredApesTest, setBoredApesTest] = useState<SymfoniBoredApesTest>(emptyContract);
    const [WEVMOS, setWEVMOS] = useState<SymfoniWEVMOS>(emptyContract);
    const [OrbitCollectionRegistryV2, setOrbitCollectionRegistryV2] = useState<SymfoniOrbitCollectionRegistryV2>(emptyContract);
    const [OrbitalApes, setOrbitalApes] = useState<SymfoniOrbitalApes>(emptyContract);
    const [ERC20, setERC20] = useState<SymfoniERC20>(emptyContract);
    const [ERC721, setERC721] = useState<SymfoniERC721>(emptyContract);
    useEffect(() => {
        if (messages.length > 0)
            console.debug(messages.pop())
    }, [messages])

    const getProvider = async (): Promise<{ provider: providers.Provider, hardhatProviderName: string } | undefined> => {
        let hardhatProviderName = "Not set";
        let _providerPriority = [...providerPriority];
        // Fallback provider
        if (fallbackProvider && autoInit && initializeCounter === 0) {
            if (localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER") === null) {
                _providerPriority = _providerPriority.sort((a, b) => {
                    return a === fallbackProvider ? -1 : b === fallbackProvider ? 1 : 0;
                })
            }
        }
        const provider = await _providerPriority.reduce(async (maybeProvider: Promise<providers.Provider | undefined>, providerIdentification) => {
            let foundProvider = await maybeProvider
            if (foundProvider) {
                return Promise.resolve(foundProvider)
            }
            else {
                switch (providerIdentification.toLowerCase()) {
                    case "web3modal":
                        try {
                            const provider = await getWeb3ModalProvider()
                            const web3provider = new ethers.providers.Web3Provider(provider);
                            hardhatProviderName = "web3modal";
                            return Promise.resolve(web3provider)
                        } catch (error) {
                            return Promise.resolve(undefined)
                        }
                    case "hardhat":
                        try {
                            const provider = new ethers.providers.JsonRpcProvider({
                                url: "http://127.0.0.1:8545",
                            });
                            hardhatProviderName = "hardhat";
                            return Promise.resolve(provider)
                        } catch (error) {
                            return Promise.resolve(undefined)
                        } default:
                        return Promise.resolve(undefined)
                }
            }
        }, Promise.resolve(undefined)) // end reduce
        return provider ? { provider, hardhatProviderName } : undefined
    };
    const getSigner = async (_provider: providers.Provider, hardhatProviderName: string): Promise<Signer | undefined> => {
        switch (hardhatProviderName) {
            case "web3modal":
                const web3provider = _provider as ethers.providers.Web3Provider
                return await web3provider.getSigner()
            case "hardhat":
                return ethers.Wallet.fromMnemonic("test test test test test test test test test test test junk").connect(_provider)
            default:
                return undefined
        }
    };
    const getWeb3ModalProvider = async (): Promise<any> => {
        const providerOptions: IProviderOptions = {

        };
        const web3Modal = new Web3Modal({
            // network: "mainnet",
            cacheProvider: false,
            providerOptions, // required
        });
        return await web3Modal.connect();
    };

    useEffect(() => {
        let subscribed = true
        const doAsync = async () => {
            const finish = (text: string) => {
                setLoading(false)
                setMessages(old => [...old, text])
            }
            const finishWithContracts = (text: string) => {
                setRafflePool(getRafflePool(_provider, _signer))
                setRaffleTokenRegistry(getRaffleTokenRegistry(_provider, _signer))
                setVerifiedCreatorRegistry(getVerifiedCreatorRegistry(_provider, _signer))
                setRumbleRewardsDistributor(getRumbleRewardsDistributor(_provider, _signer))
                setNFTRaffleAddressRegistry(getNFTRaffleAddressRegistry(_provider, _signer))
                setNFTRafflePoolController(getNFTRafflePoolController(_provider, _signer))
                setTokenRaffleAddressRegistry(getTokenRaffleAddressRegistry(_provider, _signer))
                setNFTRafflePoolFactory(getNFTRafflePoolFactory(_provider, _signer))
                setTokenRafflePoolController(getTokenRafflePoolController(_provider, _signer))
                setTokenRafflePoolFactory(getTokenRafflePoolFactory(_provider, _signer))
                setCurrency(getCurrency(_provider, _signer))
                setDIARandomOracle(getDIARandomOracle(_provider, _signer))
                setBoredApesTest(getBoredApesTest(_provider, _signer))
                setWEVMOS(getWEVMOS(_provider, _signer))
                setOrbitCollectionRegistryV2(getOrbitCollectionRegistryV2(_provider, _signer))
                setOrbitalApes(getOrbitalApes(_provider, _signer))
                setERC20(getERC20(_provider, _signer))
                setERC721(getERC721(_provider, _signer))
                finish(text)
            }
            if (!autoInit && initializeCounter === 0) return finish("Auto init turned off.")
            setLoading(true)
            setMessages(old => [...old, "Initiating Symfoni React"])
            const providerObject = await getProvider() // getProvider can actually return undefined, see issue https://github.com/microsoft/TypeScript/issues/11094

            if (!subscribed || !providerObject) return finish("No provider or signer.")
            const _provider = providerObject.provider
            setProvider(_provider)
            setMessages(old => [...old, "Useing " + providerObject.hardhatProviderName])
            setCurrentHardhatProvider(providerObject.hardhatProviderName)
            const _signer = await getSigner(_provider, providerObject.hardhatProviderName);

            if (!subscribed || !_signer) return finishWithContracts("Provider, without signer.")
            setSigner(_signer)
            setMessages(old => [...old, "Useing signer"])
            const address = await _signer.getAddress()

            if (!subscribed || !address) return finishWithContracts("Provider and signer, without address.")
            setCurrentAddress(address)

            return finishWithContracts("Completed Symfoni context initialization.")
        };
        doAsync();
        return () => { subscribed = false }
    }, [initializeCounter])

    const getRafflePool = (_provider: providers.Provider, _signer?: Signer) => {
        let instance = _signer ? RafflePool__factory.connect(ethers.constants.AddressZero, _signer) : RafflePool__factory.connect(ethers.constants.AddressZero, _provider)
        const contract: SymfoniRafflePool = {
            instance: instance,
            factory: _signer ? new RafflePool__factory(_signer) : undefined,
        }
        return contract
    }
        ;
    const getRaffleTokenRegistry = (_provider: providers.Provider, _signer?: Signer) => {

        const contractAddress = RaffleTokenRegistryDeployment.receipt.contractAddress
        const instance = _signer ? RaffleTokenRegistry__factory.connect(contractAddress, _signer) : RaffleTokenRegistry__factory.connect(contractAddress, _provider)
        const contract: SymfoniRaffleTokenRegistry = {
            instance: instance,
            factory: _signer ? new RaffleTokenRegistry__factory(_signer) : undefined,
        }
        return contract
    }
        ;
    const getVerifiedCreatorRegistry = (_provider: providers.Provider, _signer?: Signer) => {

        const contractAddress = VerifiedCreatorRegistryDeployment.receipt.contractAddress
        const instance = _signer ? VerifiedCreatorRegistry__factory.connect(contractAddress, _signer) : VerifiedCreatorRegistry__factory.connect(contractAddress, _provider)
        const contract: SymfoniVerifiedCreatorRegistry = {
            instance: instance,
            factory: _signer ? new VerifiedCreatorRegistry__factory(_signer) : undefined,
        }
        return contract
    }
        ;
    const getRumbleRewardsDistributor = (_provider: providers.Provider, _signer?: Signer) => {

        const contractAddress = RumbleRewardsDistributorDeployment.receipt.contractAddress
        const instance = _signer ? RumbleRewardsDistributor__factory.connect(contractAddress, _signer) : RumbleRewardsDistributor__factory.connect(contractAddress, _provider)
        const contract: SymfoniRumbleRewardsDistributor = {
            instance: instance,
            factory: _signer ? new RumbleRewardsDistributor__factory(_signer) : undefined,
        }
        return contract
    }
        ;
    const getNFTRaffleAddressRegistry = (_provider: providers.Provider, _signer?: Signer) => {

        const contractAddress = NFTRaffleAddressRegistryDeployment.receipt.contractAddress
        const instance = _signer ? NFTRaffleAddressRegistry__factory.connect(contractAddress, _signer) : NFTRaffleAddressRegistry__factory.connect(contractAddress, _provider)
        const contract: SymfoniNFTRaffleAddressRegistry = {
            instance: instance,
            factory: _signer ? new NFTRaffleAddressRegistry__factory(_signer) : undefined,
        }
        return contract
    }
        ;
    const getNFTRafflePoolController = (_provider: providers.Provider, _signer?: Signer) => {

        const contractAddress = NFTRafflePoolControllerDeployment.receipt.contractAddress
        const instance = _signer ? NFTRafflePoolController__factory.connect(contractAddress, _signer) : NFTRafflePoolController__factory.connect(contractAddress, _provider)
        const contract: SymfoniNFTRafflePoolController = {
            instance: instance,
            factory: _signer ? new NFTRafflePoolController__factory(_signer) : undefined,
        }
        return contract
    }
        ;
    const getTokenRaffleAddressRegistry = (_provider: providers.Provider, _signer?: Signer) => {

        const contractAddress = TokenRaffleAddressRegistryDeployment.receipt.contractAddress
        const instance = _signer ? TokenRaffleAddressRegistry__factory.connect(contractAddress, _signer) : TokenRaffleAddressRegistry__factory.connect(contractAddress, _provider)
        const contract: SymfoniTokenRaffleAddressRegistry = {
            instance: instance,
            factory: _signer ? new TokenRaffleAddressRegistry__factory(_signer) : undefined,
        }
        return contract
    }
        ;
    const getNFTRafflePoolFactory = (_provider: providers.Provider, _signer?: Signer) => {

        const contractAddress = NFTRafflePoolFactoryDeployment.receipt.contractAddress
        const instance = _signer ? NFTRafflePoolFactory__factory.connect(contractAddress, _signer) : NFTRafflePoolFactory__factory.connect(contractAddress, _provider)
        const contract: SymfoniNFTRafflePoolFactory = {
            instance: instance,
            factory: _signer ? new NFTRafflePoolFactory__factory(_signer) : undefined,
        }
        return contract
    }
        ;
    const getTokenRafflePoolController = (_provider: providers.Provider, _signer?: Signer) => {

        const contractAddress = TokenRafflePoolControllerDeployment.receipt.contractAddress
        const instance = _signer ? TokenRafflePoolController__factory.connect(contractAddress, _signer) : TokenRafflePoolController__factory.connect(contractAddress, _provider)
        const contract: SymfoniTokenRafflePoolController = {
            instance: instance,
            factory: _signer ? new TokenRafflePoolController__factory(_signer) : undefined,
        }
        return contract
    }
        ;
    const getTokenRafflePoolFactory = (_provider: providers.Provider, _signer?: Signer) => {

        const contractAddress = TokenRafflePoolFactoryDeployment.receipt.contractAddress
        const instance = _signer ? TokenRafflePoolFactory__factory.connect(contractAddress, _signer) : TokenRafflePoolFactory__factory.connect(contractAddress, _provider)
        const contract: SymfoniTokenRafflePoolFactory = {
            instance: instance,
            factory: _signer ? new TokenRafflePoolFactory__factory(_signer) : undefined,
        }
        return contract
    }
        ;
    const getCurrency = (_provider: providers.Provider, _signer?: Signer) => {
        let instance = _signer ? Currency__factory.connect(ethers.constants.AddressZero, _signer) : Currency__factory.connect(ethers.constants.AddressZero, _provider)
        const contract: SymfoniCurrency = {
            instance: instance,
            factory: _signer ? new Currency__factory(_signer) : undefined,
        }
        return contract
    }
        ;
    const getDIARandomOracle = (_provider: providers.Provider, _signer?: Signer) => {
        let instance = _signer ? DIARandomOracle__factory.connect(ethers.constants.AddressZero, _signer) : DIARandomOracle__factory.connect(ethers.constants.AddressZero, _provider)
        const contract: SymfoniDIARandomOracle = {
            instance: instance,
            factory: _signer ? new DIARandomOracle__factory(_signer) : undefined,
        }
        return contract
    }
        ;
    const getBoredApesTest = (_provider: providers.Provider, _signer?: Signer) => {
        let instance = _signer ? BoredApesTest__factory.connect(ethers.constants.AddressZero, _signer) : BoredApesTest__factory.connect(ethers.constants.AddressZero, _provider)
        const contract: SymfoniBoredApesTest = {
            instance: instance,
            factory: _signer ? new BoredApesTest__factory(_signer) : undefined,
        }
        return contract
    }
        ;
    const getWEVMOS = (_provider: providers.Provider, _signer?: Signer) => {
        let instance = _signer ? WEVMOS__factory.connect(ethers.constants.AddressZero, _signer) : WEVMOS__factory.connect(ethers.constants.AddressZero, _provider)
        const contract: SymfoniWEVMOS = {
            instance: instance,
            factory: _signer ? new WEVMOS__factory(_signer) : undefined,
        }
        return contract
    }
        ;
    const getOrbitCollectionRegistryV2 = (_provider: providers.Provider, _signer?: Signer) => {
        let instance = _signer ? OrbitCollectionRegistryV2__factory.connect(ethers.constants.AddressZero, _signer) : OrbitCollectionRegistryV2__factory.connect(ethers.constants.AddressZero, _provider)
        const contract: SymfoniOrbitCollectionRegistryV2 = {
            instance: instance,
            factory: _signer ? new OrbitCollectionRegistryV2__factory(_signer) : undefined,
        }
        return contract
    }
        ;
    const getOrbitalApes = (_provider: providers.Provider, _signer?: Signer) => {
        let instance = _signer ? OrbitalApes__factory.connect(ethers.constants.AddressZero, _signer) : OrbitalApes__factory.connect(ethers.constants.AddressZero, _provider)
        const contract: SymfoniOrbitalApes = {
            instance: instance,
            factory: _signer ? new OrbitalApes__factory(_signer) : undefined,
        }
        return contract
    }
        ;
    const getERC20 = (_provider: providers.Provider, _signer?: Signer) => {
        let instance = _signer ? ERC20__factory.connect(ethers.constants.AddressZero, _signer) : ERC20__factory.connect(ethers.constants.AddressZero, _provider)
        const contract: SymfoniERC20 = {
            instance: instance,
            factory: _signer ? new ERC20__factory(_signer) : undefined,
        }
        return contract
    }
        ;
    const getERC721 = (_provider: providers.Provider, _signer?: Signer) => {
        let instance = _signer ? ERC721__factory.connect(ethers.constants.AddressZero, _signer) : ERC721__factory.connect(ethers.constants.AddressZero, _provider)
        const contract: SymfoniERC721 = {
            instance: instance,
            factory: _signer ? new ERC721__factory(_signer) : undefined,
        }
        return contract
    }
        ;

    const handleInitProvider = (provider?: string) => {
        if (provider) {
            setProviderPriority(old => old.sort((a, b) => {
                return a === provider ? -1 : b === provider ? 1 : 0;
            }))
        }
        setInitializeCounter(initializeCounter + 1)
    }
    return (
        <SymfoniContext.Provider value={{ init: (provider) => handleInitProvider(provider), providers: providerPriority, currentHardhatProvider, loading, messages }}>
            <ProviderContext.Provider value={[provider, setProvider]}>
                <SignerContext.Provider value={[signer, setSigner]}>
                    <CurrentAddressContext.Provider value={[currentAddress, setCurrentAddress]}>
                        <RafflePoolContext.Provider value={RafflePool}>
                            <RaffleTokenRegistryContext.Provider value={RaffleTokenRegistry}>
                                <VerifiedCreatorRegistryContext.Provider value={VerifiedCreatorRegistry}>
                                    <RumbleRewardsDistributorContext.Provider value={RumbleRewardsDistributor}>
                                        <NFTRaffleAddressRegistryContext.Provider value={NFTRaffleAddressRegistry}>
                                            <NFTRafflePoolControllerContext.Provider value={NFTRafflePoolController}>
                                                <TokenRaffleAddressRegistryContext.Provider value={TokenRaffleAddressRegistry}>
                                                    <NFTRafflePoolFactoryContext.Provider value={NFTRafflePoolFactory}>
                                                        <TokenRafflePoolControllerContext.Provider value={TokenRafflePoolController}>
                                                            <TokenRafflePoolFactoryContext.Provider value={TokenRafflePoolFactory}>
                                                                <CurrencyContext.Provider value={Currency}>
                                                                    <DIARandomOracleContext.Provider value={DIARandomOracle}>
                                                                        <BoredApesTestContext.Provider value={BoredApesTest}>
                                                                            <WEVMOSContext.Provider value={WEVMOS}>
                                                                                <OrbitCollectionRegistryV2Context.Provider value={OrbitCollectionRegistryV2}>
                                                                                    <OrbitalApesContext.Provider value={OrbitalApes}>
                                                                                        <ERC20Context.Provider value={ERC20}>
                                                                                            <ERC721Context.Provider value={ERC721}>
                                                                                                {showLoading && loading ?
                                                                                                    props.loadingComponent
                                                                                                        ? props.loadingComponent
                                                                                                        : <div>
                                                                                                            {messages.map((msg, i) => (
                                                                                                                <p key={i}>{msg}</p>
                                                                                                            ))}
                                                                                                        </div>
                                                                                                    : props.children
                                                                                                }
                                                                                            </ERC721Context.Provider >
                                                                                        </ERC20Context.Provider >
                                                                                    </OrbitalApesContext.Provider >
                                                                                </OrbitCollectionRegistryV2Context.Provider >
                                                                            </WEVMOSContext.Provider >
                                                                        </BoredApesTestContext.Provider >
                                                                    </DIARandomOracleContext.Provider >
                                                                </CurrencyContext.Provider >
                                                            </TokenRafflePoolFactoryContext.Provider >
                                                        </TokenRafflePoolControllerContext.Provider >
                                                    </NFTRafflePoolFactoryContext.Provider >
                                                </TokenRaffleAddressRegistryContext.Provider >
                                            </NFTRafflePoolControllerContext.Provider >
                                        </NFTRaffleAddressRegistryContext.Provider >
                                    </RumbleRewardsDistributorContext.Provider >
                                </VerifiedCreatorRegistryContext.Provider >
                            </RaffleTokenRegistryContext.Provider >
                        </RafflePoolContext.Provider >
                    </CurrentAddressContext.Provider>
                </SignerContext.Provider>
            </ProviderContext.Provider>
        </SymfoniContext.Provider>
    )

};
