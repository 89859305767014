import ENS, { getEnsAddress } from "@evmosdomains/sdk";
import { ethers } from "ethers";
let provider;
let ens;
try {
  provider = new ethers.providers.JsonRpcProvider(
    "https://eth.bd.evmos.org:8545"
  );
  ens = new ENS({ provider, ensAddress: getEnsAddress("9001") });
} catch (error) {
  console.log(error);
}

export function isSameAddress(address, owner) {
  return address?.toLowerCase() === owner?.toLowerCase();
}

let nameCache = {};
async function getNameCached(address) {
  if (ens == null) {
    return { name: null };
  }
  if (nameCache[address] == null) {
    nameCache[address] = await ens.getName(address);
  }
  return nameCache[address];
}

export async function getName(address, length) {
  const { name } = await getNameCached(address);
  return name ? name.substring(0, 25) : null;
}
