import cs from "classnames";

function CampaignSwitch({ campaign, setCampaign }) {
  return (
    <div className="flex mt-16 border h-30 text-white rounded border-purple">
      <button
        onClick={() => setCampaign(0)}
        className={cs(
          "cursor-pointer border-r rounded-l border-purple px-5 py-0.5 hover:bg-gradientTwo",
          { "bg-purple bg-opacity-40": campaign === 0 }
        )}
      >
        Top Campaigns
      </button>
      {/* <button
        onClick={() => setCampaign(1)}
        className={cs(
          "cursor-pointer border-purple px-5 rounded-r hover:bg-gradientTwo",
          {
            "bg-purple bg-opacity-40": campaign === 1,
          }
        )}
      >
        Auto Funded Campaigns
      </button> */}
    </div>
  );
}

export default CampaignSwitch;
