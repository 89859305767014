import MyPrizeCard from "../components/MyPrizeCard";
import { useEVM } from "../hooks/EVMhook";
import { MyPrizes } from "../graphql/api";
import { useEffect, useState } from "react";

function MyPrizesPage() {
  const { address } = useEVM();
  const [data, setData] = useState();
  const [fetching, setFetching] = useState();

  useEffect(() => {
    if (address) {
      setFetching(true);
      MyPrizes(address).then((d) => {
        setFetching(false);
        setData(d);
      });
    }
  }, [address]);

  return (
    <div className="max-w-6xl w-full gap-10 flex flex-wrap">
      {fetching && (
        <span className="text-white font-semibold text-xl">Loading...</span>
      )}
      {!fetching && data?.map((d) => <MyPrizeCard key={d.id} data={d} />)}
    </div>
  );
}

export default MyPrizesPage;
