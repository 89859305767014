import { useEVM } from "../hooks/EVMhook";
import Add from "../assets/icons/add2.svg";
import Minus from "../assets/icons/delete.svg";
import { useEffect, useState } from "react";
import { getCurrency, getPoolId } from "../helpers/pools";
import { useApproveERC20 } from "../hooks/marketTransactions";
import { handleError } from "../helpers/errors";
import { dismissToast, loadingToast, successToast } from "./Toasts";
import { formatEther } from "../helpers/payTokens";

const { ethers } = require("ethers");

function Mint({ data }) {
  const [count, setCount] = useState(1);
  const { getController, address } = useEVM();
  const [_loading, setLoading] = useState(false);
  const [approveNeeded, setApproveNeeded] = useState();

  const {
    approve,
    checkApproval,
    loading: loadingApprove,
  } = useApproveERC20((step) => {
    setApproveNeeded(step !== 2);
  });

  const loading = _loading || loadingApprove;

  useEffect(() => {
    async function fetchMintData() {
      const mintService = await getController(data);
      checkApproval(address, data?.payToken, mintService?.instance?.address);
    }
    if (address && data?.payToken) {
      fetchMintData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, data?.payToken]);

  async function handleMint() {
    let toastId;
    setLoading(true);
    try {
      const mintService = await getController(data);
      const price = ethers.utils.parseEther(
        ethers.utils.formatEther(data.price)
      );
      const mulPrice = price.mul(parseInt(count));
      const transaction = await mintService?.instance?.mint(
        getPoolId(data),
        count,
        {
          value: mulPrice,
        }
      );
      toastId = loadingToast(`Purchasing raffle ticket...`);
      mintService.instance.provider
        .waitForTransaction(transaction.hash)
        .then(async () => {
          dismissToast(toastId);
          setLoading(false);
          successToast("Purchase complete!", {
            duration: 4000,
          });
        });
    } catch (error) {
      console.log(error);
      handleError(error);
      setLoading(false);
    }
  }

  async function handleApprove() {
    const mintService = await getController(data);
    approve(data?.payToken, mintService?.instance?.address);
  }

  async function handleERCMint() {
    let toastId;
    setLoading(true);
    try {
      const mintService = await getController(data);
      const transaction = await mintService?.instance?.mint(
        getPoolId(data),
        parseInt(count)
      );
      toastId = loadingToast(`Purchasing raffle ticket...`);
      mintService.instance.provider
        .waitForTransaction(transaction.hash)
        .then(async () => {
          dismissToast(toastId);
          setLoading(false);
          successToast("Purchase complete!", {
            duration: 4000,
          });
        });
    } catch (error) {
      console.log(error);
      handleError(error);
      setLoading(false);
    }
  }

  function handleMintClick() {
    if (data.payToken === "0x0000000000000000000000000000000000000000") {
      handleMint();
    } else {
      handleERCMint();
    }
  }

  return (
    <div className="flex gap-5 flex-col">
      <div className="flex items-center mt-1 gap-2">
        <span className="text-purple font-bold text-xl">
          {data?.price
            ? parseFloat(formatEther(data.price, data.payToken)).toFixed(2)
            : 0}{" "}
          {getCurrency(data)}
        </span>
        <span className="text-white font-bold text-lg">per ticket</span>
      </div>
      <div className="flex gap-3 -mt-2 md:flex-row flex-col items-center">
        <span className="text-white self-start md:self-center -mb-2 font-bold text-lg">
          Quantity
        </span>
        <div className="flex mt-2 gap-3">
          <div className="flex  gap-4">
            <div className="bg-black bg-opacity-0 rounded-full border py-0 justify-between items-center flex h-10 px-1.5 border-purple ">
              <button onClick={() => (count > 1 ? setCount(count - 1) : 1)}>
                <img
                  alt="slector"
                  className="md:w-6 md:h-6 w-5 h-5"
                  src={Minus}
                />
              </button>
              <span className="text-center h-7 -mt-1.5 text-white text-lg md:text-2xl font-medium w-14">
                {count}
              </span>
              <button onClick={() => (count < 20 ? setCount(count + 1) : 20)}>
                <img
                  alt="slector"
                  className="md:w-6 md:h-6 w-5 h-5"
                  src={Add}
                />
              </button>
            </div>
            {!approveNeeded && (
              <button
                onClick={() => handleMintClick()}
                className="outline-none h-10 font-bold text-lg text-white hover:text-white bg-purple  w-40  px-2 rounded-full"
              >
                {loading && "Loading.."}
                {!loading && <>{`MINT TICKET${count > 1 ? "S" : ""} `}</>}
              </button>
            )}
            {approveNeeded && (
              <button
                onClick={() => handleApprove()}
                className="outline-none h-10 font-bold text-lg text-white hover:text-white bg-purple  w-40  px-2 rounded-full"
              >
                {loading && "Loading.."}
                {!loading && <>{`APPROVE`}</>}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mint;
