import BG from "../assets/layout/Coins.png";
import WinnerCard from "../components/WinnerCards";
import { useEffect, useState } from "react";
import { useEVM } from "../hooks/EVMhook";
import { PastPools } from "../graphql/api";
import Footer from "./Footer";

function Winners() {
  const { address } = useEVM();
  const [data, setData] = useState();
  const [fetching, setFetching] = useState();

  useEffect(() => {
    PastPools().then((d) => {
      setFetching(false);
      setData(d);
    });
  }, [address]);

  return (
    <div className="flex bg-dark w-full  items-center flex-col">
      <div className="flex bg-dark w-full max-w-6xl  flex-col">
        <div
          className="overflow-x-hidden  absolute z-20 top-0 -mt-2 overflow-y-hidden rounded-b-lg md:rounded-b-fulll w-full flex justify-center"
          style={{
            backgroundImage: `url(${BG})`,
            height: "540px",
            width: "170%",
          }}
        >
          {/* <img
            // style={{ minWidth: "1000px", width: "1900px", height: "1000px" }}
            alt="bg"
            src={BG}
            className="z-40 w-full -mt-32"
          /> */}
        </div>

        <div className="z-40 mb-32 mt-10 w-full flex flex-col">
          <span className="text-purple w-full text-center text-4xl font-bold">
            PAST RAFFLES
          </span>
          <span className="text-white w-full text-center text-xl font-semibold">
            Checkout raffles completed in the past
          </span>
        </div>
        <div className="flex px-10 flex-wrap gap-8">
          {fetching && (
            <span className="text-white z-40 animate-pulse w-full text-center font-bold text-3xl">
              Loading...
            </span>
          )}
          {!fetching &&
            data?.map((d) => {
              return <WinnerCard data={d} />;
            })}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Winners;
