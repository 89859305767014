import Dropdown from "react-dropdown";
import RightArrow from "../../assets/icons/arrow-right-svgrepo-com.svg";
import { payTokenMeta } from "../../helpers/payTokens";

const _options = Object.keys(payTokenMeta).map((token) => {
  return {
    wrapped: payTokenMeta[token].wrapped,
    erc: payTokenMeta[token].erc,
    hide: payTokenMeta[token].hide,
    value: token,
    label: (
      <div className="flex items-center gap-1">
        <img
          className={` ${payTokenMeta[token].class}`}
          alt="eth"
          src={payTokenMeta[token].image}
        />
        <span>{payTokenMeta[token].name}</span>
      </div>
    ),
  };
});

const BG_COLOUR = "bg-darkGray";

export const options = _options.map((o) => ({
  ...o,
  className: `border-t hover:bg-dark cursor-pointer border-dark border-solid mx-2 p-3 py-1.5 ${BG_COLOUR} font-bold`,
}));

export default function ListingCoinDropdown({
  value,
  onChange,
  ercOnly,
  nativeOnly,
}) {
  function getOptions() {
    let filteredOptions = options;
    if (ercOnly) {
      filteredOptions = filteredOptions.filter((item) => item.erc === true);
    }
    if (nativeOnly) {
      filteredOptions = filteredOptions.filter(
        (item) => item.wrapped === false
      );
    }

    return filteredOptions.filter((item) =>item.hide !== true);
  }
  return (
    <div style={{ width: 155, height: 37 }}>
      <div className="h-full z-40">
        <Dropdown
          arrowClosed={
            <img
              alt="arrow"
              className="transform origin-center rotate-90"
              src={RightArrow}
            />
          }
          arrowOpen={
            <img
              alt="arrow"
              className="transform origin-center -rotate-90"
              src={RightArrow}
            />
          }
          placeholder={"Choose token"}
          options={getOptions()}
          onChange={onChange}
          value={value}
          className={`border cursor-pointer border-darkGray border-solid rounded ${BG_COLOUR} shadow-lg shadow-black`}
          controlClassName="px-2 pt-1 h-9 w-26 flex flex-row justify-between"
        />
      </div>
    </div>
  );
}
